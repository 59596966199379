// Autocomplete search dropdown
.autocomplete-search-dropdown-popper {
  box-shadow: 0 0 3.2rem 0 $purple7 !important;
  z-index: 1500;
  background-color: $white;
  .MuiPaper-root {
    ul {
      padding: 0 !important;
      .MuiAutocomplete-option {
        background-color: transparent;
        &:hover,
        &:focus {
          background-color: $lightPurple;
        }
        .search-spaces-dropdown {
          .left-side {
            .multiple-user-icon {
              .icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
              }
              &.initials {
                border-radius: 0.417rem;
                .icon {
                  width: 1.6rem;
                  height: 1.6rem;
                  line-height: 1.6rem;
                }
              }
            }
            .user-description {
              display: inline;
              max-width: 38rem;
              @extend .truncated-text;
              @include bp(sm-max) {
                max-width: 32rem;
              }
            }
          }
        }
      }
    }
  }
  &.create-goal-dropdown {
    ul {
      height: 22rem !important;
    }
  }
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      max-height: 22rem;
    }
  }
}

.MuiPopper-root.MuiAutocomplete-popper,
.base-Popper-root.MuiAutocomplete-popper {
  .MuiAutocomplete-option.Mui-focused {
    flex-wrap: wrap;
    background-color: $lightPurple;
  }
  .search-members-dropdown,
  .search-spaces-dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .initials {
      height: 3rem;
      width: 3rem;
      min-width: 3rem;
      border-radius: 50%;
      background: $purple3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      line-height: 1.33;
      font-weight: 600;
      color: $white;
      margin: 0 1.6rem 0 0;
      text-transform: uppercase;
      .icon.be-user {
        height: 1.6rem;
      }
      &.multiple-user-icon {
        height: 2.4rem;
        width: 2.4rem;
        min-width: 2.4rem;
      }
    }
    .name {
      font-size: 1.4rem;
      font-weight: 500;
      @include line-height(14, 20);
      letter-spacing: 0.014rem;
      color: $black1;
      margin: 0 0.8rem 0 0;
      &:empty {
        display: none;
      }
    }
    .email {
      color: $trueGrey;
      font-size: 1.4rem;
      font-weight: normal;
    }
    .left-side {
      display: flex;
      align-items: center;
      .user-details {
        max-width: 38rem;
        @extend .truncated-text;
        @include bp(sm-max) {
          max-width: 32rem;
        }
      }
      .be-buildcard-box {
        display: inline-flex;
        align-items: center;
        margin-right: 0.6rem;
      }
    }
    .right-side {
      display: block;
      //width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
      @extend .truncated-text;
      .MuiCheckbox-root {
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;
        padding: 0.6rem;
        &:hover {
          background-color: rgba(98, 0, 234, 0.04);
        }
      }
    }
  }
  .MuiAutocomplete-option {
    padding: 0.8rem 1rem 0.8rem 1.6rem !important;
  }
}

.MuiPopper-root.MuiAutocomplete-popper {
  &.bmeet-search-dropdown {
    .MuiPaper-root {
      .MuiAutocomplete-listbox {
        padding: 0;
      }
    }
  }
  .search-members-dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .left-side {
      .user-description {
        display: inline;
        max-width: 38rem;
        @extend .truncated-text;
        @include bp(sm-max) {
          max-width: 32rem;
        }
      }
    }
    .right-side {
      display: block;
      //width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
      @extend .truncated-text;
      .MuiButtonBase-root {
        justify-content: flex-end;
        &:hover {
          background-color: rgba(98, 0, 234, 0.04);
        }
      }
    }
  }
}
.searchbar-wrap {
  .MuiFormControl-root {
    .MuiInputBase-root {
      display: flex;
      flex-flow: row-reverse;
      height: 6rem;
      .MuiInputAdornment-root {
        margin: 0 1.6rem 0 0;
      }
    }
  }
  .MuiAutocomplete-endAdornment {
    .MuiButtonBase-root {
      &::after {
        display: none;
      }
      &:hover {
        background: transparent;
        transition: none;
      }
      &.MuiAutocomplete-popupIndicatorOpen,
      &.MuiAutocomplete-popupIndicator {
        transform: none;
        transition: none;
      }
    }
  }
  .search-members-dropdown {
    ::placeholder {
      color: $trueGrey !important;
    }
  }
}
