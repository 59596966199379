.compnay-settings-page {
  .settings-title {
    background-color: $grey7;
    border-bottom: 0.1rem solid $grey2;
    padding: 1.5rem 2.4rem 1rem;
    @include bp(md-max) {
      padding: 0rem 0rem 1rem;
      margin: 1.6rem;
      position: relative;
    }
    .title-right {
      margin-top: -1rem;
    }
    .bottom-title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include bp(lg-max) {
        margin-top: 0;
      }
      h2 {
        margin: 0;
      }
      .MuiButton-root {
        font-size: 1.4rem;
        letter-spacing: 0.01em;
        min-width: 12.9rem;
        height: 3.6rem;
      }
      .title-left {
        @include bp(sm-max) {
          width: 100%;
        }
      }
      .title-right {
        @include bp(sm-max) {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }

  .settings-wrapper {
    margin-top: 4.2rem;
    @include bp(lg-max) {
      margin-top: 3rem;
    }

    @include bp(smd-max) {
      margin-bottom: 2rem;
    }

    .settings-outer {
      padding-bottom: 3.2rem;
      margin-bottom: 3.2rem;
      border-bottom: 0.1rem solid $grey3;
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
      .form-error {
        margin-top: 3.2rem;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.01em;
      }
      .title-block {
        margin-bottom: 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        h5 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 0;
          @include bp(sm-max) {
            flex-direction: column;
            align-items: flex-start;
          }
          .custom-chip {
            margin-left: 0.8rem;
            @include bp(sm-max) {
              margin-left: 0;
            }
          }
        }
        p {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: 0.01em;
          color: $grey4;
        }
        .left-title {
          @include bp(sm-max) {
            width: 100%;
          }
        }
        .right-title {
          @include bp(sm-max) {
            width: 100%;
            margin-top: 1.2rem;
          }
        }
        &.company-name {
          h5 {
            margin-bottom: 1.6rem;
          }
          .MuiFormControl-root {
            .MuiInputBase-root {
              width: 100%;
              max-width: 57.6rem;
              @include bp(sm-max) {
                max-width: 100%;
              }
              .MuiInputBase-input {
                padding: 1.2rem 1.6rem;
                font-size: 1.6rem;
                @include line-height(16, 24);
              }
              ::placeholder {
                color: $grey11 !important;
              }
            }
          }
        }
      }
      .compnay-photo-block {
        max-width: 42.4rem;
        margin-top: 3.2rem;
        .photo-upload-box {
          &.is-horizontal {
            .instructions {
              padding-right: 2.4rem;
              &.has-btns {
                padding-right: 0;
              }
              @include bp(sm-max) {
                padding-right: 0;
              }
            }
          }
        }
        .photo-guidelines {
          margin-top: 3.2rem;
          color: $grey4;
          p {
            margin: 0;
          }
        }
      }
      .domain-wrapper {
        display: flex;
        align-items: center;
        .form-group {
          width: 28.3rem;
          max-width: 100%;
        }
        .existing-url {
          padding-left: 0.8rem;
          font-weight: 500;
          color: $trueGrey;
          white-space: nowrap;
        }
      }
      .color-checkbox-wrapper {
        margin-top: 1.9rem;
      }
      .radio-wrapper {
        margin-top: 3.3rem;
        &.two-fa {
          .MuiFormGroup-root {
            .row {
              .col-md-6 {
                display: inline-flex;
                .box-radio {
                  //padding-bottom: 1rem;
                  .radio-content {
                    padding-top: 0.6rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
