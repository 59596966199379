.form-group {
  margin-bottom: 1.6rem;
}

.form-error {
  display: block;
  margin-top: 0.6rem;
  color: $red !important;
  font-size: 1.3rem;
}

.has-error {
  .MuiInputLabel-root {
    &.Mui-focused {
      color: $red;
    }
  }
  .MuiFilledInput-root {
    border-color: $red !important;
  }
  &.MuiInputBase-root {
    border-color: $red !important;
  }
}
.has-error {
  border-color: $red !important;
}
.input-error {
  border-color: $red !important;
  fieldset { border-color: $red !important; }
  &.MuiInputBase-root, .MuiInputBase-root {
    border-color: $red !important;
  }
}

.has-add-email {
  position: relative;
  .add-email-wrapper {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    border: 0.1rem solid $grey8;
    border-radius: 0 0 0.4rem 0.4rem;
    z-index: 4;
    background: $white;    
    box-shadow: 0px 2px 4px 0px #00000026;
    .add-email-box {
      cursor: pointer;
      padding: 1.6rem;
      border-left: 0.4rem solid $purple1;
      background: $grey7;
      font-weight: 400;
      font-size: 1.4rem;
      @include line-height(14, 20);
      letter-spacing: 0.01em;
      @extend .truncated-text;
    }
    &.invite {
      .add-email-box {
        overflow: visible;
        white-space: normal;
      }
    }
  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:focus {
//   -webkit-box-shadow: 0 0 0 5rem $white inset;
//   font-size: 1.6rem;
//   line-height: 2.4rem;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.MuiIconButton-root {
  &.MuiAutocomplete-popupIndicator {
    @extend .transition;
    svg {
      display: none;
    }
    &:after {
      content: "\6f";
      font-family: $enterpriseFont;
      font-size: 1.4rem;
      line-height: 0;
      position: absolute;
      right: 1.6rem;
      top: 50%;
      color: $black1;
      @extend .transition;
    }
  }
}

.MuiInputBase-root {
  &.MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $grey;
        border-width: 0.1rem;
      }
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 0.1rem;
        border-color: $purple1;
      }
    }
  }
}

.MuiOutlinedInput-root {
  &.borderless-dropdown {
    .MuiSelect-select {
      padding: 0;
      padding-right: 3rem !important;
      height: 3.6rem;
      display: flex;
      align-items: center;
      position: relative;
      line-height: 3.6rem;
      &::after {
        content: "\4e";
        font-family: $enterpriseFont;
        position: absolute;
        right: 0;
        top: calc(50% - 1.3rem);
        height: 2.4rem;
        width: 2.4rem;
        font-size: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiSelect-icon {
      display: none;
    }
  }
}

.MuiInputBase-formControl {
  &.Mui-focused {
    .MuiSelect-select {
      &::after {
        @include rotate(180deg);
      }
    }
  }
  &.no-label {
    .MuiSelect-select {
      padding: 1.85rem 4.3rem 1.85rem 1.6rem !important;
    }
    &.sm-height {
      .MuiSelect-select {
        padding: 1.3rem 4.3rem 1.3rem 1.6rem !important;
      }
    }
  }
  &.borderless-dropdown {
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
  .MuiSelect-select {
    padding: 2.8rem 4.3rem 0.9rem 1.6rem !important;
    ~ .MuiSvgIcon-root {
      display: none;
    }
    &::after {
      content: "\4e";
      font-family: $enterpriseFont;
      font-size: 1.4rem;
      line-height: 0;
      position: absolute;
      right: 2.1rem;
      top: 50%;
      color: $black1;
      @extend .transition;
    }
  }
}

.MuiInputBase-input {
  @include custom-scroll;
  @include placeholder {
    display: flex;
    font-size: 1.4rem !important;
    line-height: 1 !important;
    color: $grey11 !important;
    opacity: 1 !important;
  }
}

.MuiAutocomplete-root {
  background-color: $white;
  .MuiAutocomplete-inputRoot {
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }
  .MuiOutlinedInput-root {
    padding: 0 6rem 0 1.6rem !important;
    border: 0.1rem solid $grey;
    @extend .transition;
    &.Mui-focused {
      border-color: $purple1;
    }
    .MuiAutocomplete-input {
      padding: 2.7rem 0 0.8rem !important;
    }
  }
}

[class$="MuiAutocomplete-listbox"] {
  @include custom-scroll;
}

[class$="MuiAutocomplete-clearIndicator"] {
  display: none !important;
  .MuiSvgIcon-root {
    fill: $black1;
  }
}

.checkbox-control.MuiFormControlLabel-root {
  &.top-align {
    align-items: flex-start;
    .MuiCheckbox-root {
      margin-top: -0.6rem;
    }
  }
}

.radio-control.MuiFormControlLabel-root {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 0;
  .MuiRadio-root {
    padding: 0.7rem;
  }
  .MuiFormControlLabel-label {
    width: calc(100% - 3.6rem);
    padding-left: 0.8rem;
    &.Mui-disabled {
      color: $grey12;
      .radio-content {
        color: $grey12;
      }
      .sub-content {
        color: $grey12;
      }
    }
  }
}

.radio-card {
  background: $white;
  box-shadow:
    0 2rem 3.2rem rgba(0, 0, 0, 0.08),
    0 0.2rem 0.6rem rgba(0, 0, 0, 0.05);
  border-radius: 0.8rem;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;
  .MuiFormControlLabel-root {
    display: flex;
  }
}

.radio-wrapper {
  .box-radio {
    padding: 1.7rem 1.4rem;
    border: 0.1rem solid $grey8;
    @extend .be-radius;
    background: $white;
    @extend .transition;
    display: flex;
    @extend .transition;
    margin-left: 0;

    .radio-content {
      strong {
        font-size: 1.6rem;
        @include line-height(16, 24);
      }
    }
    &.checked {
      border-color: $purple1;
      box-shadow: 0 0 0 0.1rem $purple1;
    }
    &.no-border {
      border: 0;
      padding: 0;
    }
    &.top-align {
      align-items: flex-start;
      .MuiRadio-root {
        margin-top: -0.6rem;
      }
    }
    &.disabled,
    &.Mui-disabled {
      cursor: not-allowed;
      * {
        pointer-events: none;
      }
      .MuiCheckbox-root {
        opacity: 0.5;
      }
      .radio-content {
        color: $grey12;
      }
      .sub-content {
        color: $grey12;
      }
    }
    .MuiFormControlLabel-label {
      width: calc(100% - 4rem);
    }
    .sub-content {
      font-size: 1.4rem;
      @include line-height(14, 20);
      color: $trueGrey;
      margin-top: 0.6rem;
      &.sm {
        font-size: 1.2rem;
        @include line-height(12, 16);
      }
      p {
        letter-spacing: 0.02rem;
      }
    }
  }
  [class^="col-"] {
    .MuiFormControlLabel-root.box-radio {
      @include bp(sm-max) {
        margin-bottom: 1.5rem;
      }
    }
    &:last-child {
      .MuiFormControlLabel-root.box-radio {
        margin: 0;
      }
    }
  }
}

.purple-badge {
  font-weight: 500;
  font-size: 1.4rem;
  @include line-height(14, 24);
  color: $white;
  background-color: $purple1;
  padding: 0 0.4rem;
  display: inline-block;
  border-radius: 0.4rem;
}

.hidden-input {
  @extend .absolute-pos;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  margin: 0;
  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.custom-dropmenu {
  .MuiPaper-root {
    &.MuiMenu-paper {
      box-shadow: 0 0.6rem 1.5rem -0.3rem rgba(0, 0, 0, 0.15);
      border-radius: 0.4rem;
      background: $white;
      border: 0.1rem solid $grey13;
      min-width: 16.7rem;
      .MuiMenu-list {
        padding: 0.8rem;
        .MuiMenuItem-root {
          min-height: 3.6rem;
          padding: 0 1.6rem;
          border-radius: 0.8rem;
          margin-bottom: 0.2rem;
          color: $black1;
          letter-spacing: 0.01em;
          font-size: 1.4rem;
          @include line-height(14, 20);
          display: flex;
          align-items: center;
          em[class^="be-"] {
            line-height: 0;
            margin-right: 0.8rem;
            font-size: 1.6rem;
          }
          &:last-child {
            margin: 0;
          }
          &.is-primary {
            color: $purple1;
          }
        }
      }
    }
  }
  &.action-menu-dropdown {
    .MuiPaper-root {
      &.MuiMenu-paper {
        min-width: auto;
      }
    }
  }
  &.invite {
    .MuiList-root {
      .MuiButtonBase-root {
        padding: 0.8rem 1.6rem !important;
      }
    }
  }
}

.menu-with-arrow {
  .MuiButton-root {
    &.sm-btn {
      height: 4.4rem;
      line-height: 4.4rem;
      font-weight: 400;
      font-size: 1.4rem;
      padding: 0 1rem;
    }
    &:after {
      content: "\6f";
      font-family: $enterpriseFont;
      font-size: 1rem;
      margin-left: 0.7rem;
    }
  }
}

.file-upload-wrapper {
  background: $grey1;
  border: 0.1rem dashed $grey9;
  border-radius: 0.4rem;
  text-align: center;
  padding: 3.2rem 1rem;
  @include bp(lg-max) {
    padding: 2.6rem 1rem;
  }
  .upload-icon {
    display: inline-flex;
    margin-bottom: 0.5rem;
  }
  h6 {
    margin-bottom: 0.8rem;
  }
  p {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $trueGrey;
  }
  .browse-files-link {
    position: relative;
  }
}

.file-uploading-wrapper {
  background: $white;
  border: 0.1rem solid $grey3;
  @extend .be-radius;
  padding: 2.4rem 2.6rem;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  max-width: 100%;
  @include bp(lg-max) {
    padding: 2.2rem 2rem;
  }
  @include bp(sm-max) {
    display: flex;
  }
  .MuiLinearProgress-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .file-icon {
    line-height: 0;
    color: $purple1;
    font-size: 2.2rem;
  }
  .be-close {
    line-height: 0;
    font-size: 2.2rem;
    color: $grey9;
    cursor: pointer;
    @include bp(lg-max) {
      font-size: 1.8rem;
    }
    &:hover {
      color: $purple1;
    }
  }
  .file-name {
    width: calc(100% - 4.4rem);
    padding: 0 1.6rem;
    font-weight: 500;
    @extend .truncated-text;
    &.uploaded {
      color: $purple1;
    }
    .status {
      font-weight: 400;
      color: $grey9;
      @include bp(xs-max) {
        display: block;
      }
    }
  }
}

.password-hint {
  max-width: inherit;
  line-height: 1;
  position: static;
  margin: 0 -1.6rem;
  width: calc(100% + 4.4rem);
  margin-top: 0.8rem;
  .hint-bars {
    display: flex;
    justify-content: space-between;
    .bar {
      height: 0.4rem;
      border-radius: 0.4rem;
      display: inline-block;
      width: calc(25% - 0.1rem);
      background: rgba($trueGrey, 0.2);
      @extend .transition;
      position: relative;
      overflow: hidden;
      &.active {
        &:before {
          width: 100%;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        display: block;
        width: 0;
        @extend .transition;
        background: $green1;
        border-radius: 0.4rem;
      }
    }
  }
  .hint-text {
    display: block;
    text-align: right;
    font-size: 1.2rem;
    color: $trueGrey;
    margin-top: 0.4rem;
  }
}

.photo-upload-box {
  width: 30rem;
  min-height: 30rem;
  max-width: 100%;
  padding: 3.2rem;
  border: 0.1rem dashed $grey;
  border-radius: 0.8rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &.grey-bg {
    background: $grey1;
  }
  &.lg-height {
    min-height: 38.3rem;
    @include bp(sm-max) {
      min-height: 30rem;
    }
  }
  &.is-horizontal {
    padding: 0;
    border: 0;
    width: auto;
    height: auto;
    min-height: inherit;
    align-items: flex-start;
    text-align: left;
    &.uploaded {
      .upload-icon {
        border: 0;
        background: transparent;
        height: 11rem;
        width: 11rem;
      }
    }
    .upload-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
    }
    .upload-icon {
      height: 4.8rem;
      font-size: 2.6rem;
      border: 0.1rem dashed $purple2;
      border-radius: 0.4rem;
      width: 23.2rem;
      margin: 0;
      overflow: hidden;
      @include bp(xs-max) {
        width: 100%;
      }
      &.square-placeholder {
        width: 11rem;
        height: 11rem;
      }
      .secondary-loader {
        margin: 0;
        .spinner {
          width: 2rem;
        }
      }
    }
    .instructions {
      margin: 0;
      font-size: 1.2rem !important;
      line-height: 1.6rem !important;
      letter-spacing: 0.01em !important;
      color: $grey4;
      padding-left: 2.4rem;
      width: calc(100% - 23.2rem);
      max-width: inherit;
      @include bp(xs-max) {
        width: 100%;
        padding: 0;
        margin-top: 1rem;
      }
      &.has-btns {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        .MuiButton-root {
          width: 100%;
          margin-bottom: 0.8rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .browse-files-link {
        margin-left: 0.3rem;
        font-weight: 400;
      }
    }
  }
  &.secondary {
    width: 100%;
    padding: 2.4rem;
    .instructions {
      max-width: 30rem;
    }
  }
  .photo-uploaded-wrapper {
    display: flex;
    flex-direction: column;
    height: 25rem;
    justify-content: center;
    width: 100%;
    &.has-error {
      justify-content: space-between;
      padding-top: 3.3rem;
    }
  }
  .secondary-loader {
    margin-bottom: 3rem;
  }
  .upload-icon {
    display: inline-flex;
    height: 13.2rem;
    width: 13.2rem;
    background: $lightPurple;
    border-radius: 1.2rem;
    align-items: center;
    justify-content: center;
    color: $purple2;
    font-size: 5.2rem;
    margin-bottom: 2rem;
    &.no-bg {
      background-color: transparent;
      height: 7.5rem;
      width: 7.5rem;
      margin-bottom: 1.8rem;
      .file-icon {
        width: 7.5rem;
      }
    }
  }
  .avatar {
    height: 13.2rem;
    width: 13.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $lightPurple;
    border-radius: 50%;
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 4.8rem;
    color: $purple2;
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
    .uploaded-photo {
      @extend .absolute-pos;
      @extend .cover-fit;
      display: none;
    }
    &.has-photo {
      .inititals {
        display: none;
      }
      .uploaded-photo {
        display: block;
      }
    }
  }
  .primary-upload-icon {
    line-height: 0;
    font-size: 4.8rem;
    color: $grey5;
    margin-bottom: 1.6rem;
  }
  h6 {
    margin-bottom: 0.8rem;
  }
  .instructions {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
    letter-spacing: 0.01em !important;
    max-width: 12rem;
    margin: 0 auto 2.4rem;
    &.full-width {
      max-width: inherit;
    }
  }
}

.photo-with-resize {
  .uploaded-photo {
    height: 24.5rem;
    display: flex;
    background: rgba($black1, 0.4);
    align-items: center;
    justify-content: center;
    img {
      display: block;
      max-height: 24.5rem;
    }
    .ReactCrop {
      overflow: visible;
    }
  }
  .resize-slider {
    display: flex;
    margin-top: 2.4rem;
  }
  .upload-new-photo {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
  }
}

.browse-files-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.01em;
  color: $purple1;
  z-index: 0;
  &:hover {
    color: $purple;
  }
  .icon {
    line-height: 0;
    margin-right: 0.9rem;
    margin-top: -0.2rem;
  }
  .hidden-input {
    z-index: 1;
  }
}

.new-photo-btn {
  margin-top: 1.7rem;
  display: flex;
  justify-content: center;
}

.system-msg-wrapper {
  text-align: left;
  .system-msg {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.system-msg {
  background: $light-red;
  border-left: 0.4rem solid $red;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $red;
  padding: 1.2rem 1.6rem;
}

.phone-no-group {
  display: flex;
  flex-wrap: wrap;
  .country-code-field {
    border: 0.1rem solid $grey;
    border-radius: 0.4rem;
    padding: 0.9rem 1.6rem 0;
    width: 15.1rem;
    height: 6rem;
    @include bp(xs-max) {
      width: 100%;
      margin-bottom: 1.6rem;
    }
    .cc-label {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $trueGrey;
      display: block;
    }
    .react-tel-input {
      font-family: $primaryFont;
      position: relative;
      &:after {
        content: "\6f";
        font-family: $enterpriseFont;
        color: $black1;
        font-size: 1.2rem;
        //font-weight: 600;
        position: absolute;
        right: 0.3rem;
        top: 50%;
        @include translateY(-50%);
      }
      .flag,
      .defaultCountry {
        background-image: url("../images/country-flags.png") !important;
      }
      .form-control {
        width: 11.5rem;
        border: 0;
        height: auto;
        border-radius: 0;
        background: transparent;
        padding-left: 3.2rem;
        font-size: 1.6rem;
        @include placeholder {
          font-size: 0 !important;
          color: $white !important;
          text-indent: 999 !important;
        }
      }
      .flag-dropdown {
        background: transparent;
        border: 0;
        border-radius: 0;
        width: 100%;
        z-index: 2;
      }
      .selected-flag {
        width: 100%;
        background: transparent;
        padding-left: 0;
        border-radius: 0;
        .arrow {
          display: none;
        }
      }
      .country-list {
        @include custom-scroll;
        padding: 16px;
        max-height: 19.4rem;
        margin-left: -1.6rem;
        .country {
          @extend .transition;
          font-size: 1.6rem;
          line-height: 2.2rem;
          border-radius: 0.4rem;
          margin-bottom: 0.8rem;
          &:last-child {
            margin-bottom: 0;
          }
          .dial-code {
            color: $black1;
          }
          &:hover {
            background-color: $lightPurple;
          }
        }
        .country.highlight {
          background-color: $lightPurple;
        }
      }
    }
  }
  .phone-no-field {
    width: calc(100% - 15.1rem);
    padding-left: 1.6rem;
    @include bp(xs-max) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.custom-chip {
  display: inline-flex;
  font-size: 1.2rem;
  line-height: 2.4rem;
  background: $lightPurple;
  padding: 0 0.8rem;
  border-radius: 1.2rem;
  font-weight: 500;
  &.has-shadow {
    box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.15);
  }
  &.lg {
    height: 4.8rem;
    align-items: center;
    padding: 0 1.1rem;
  }
}

.trending-chips-wrapper {
  .trend-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.1rem;
    &:last-child {
      margin-bottom: 0;
    }
    &.left-minus {
      margin-left: -2.2rem;
    }
    .custom-chip {
      margin-right: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.color-checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;
  .checkbox-outer {
    height: 5.6rem;
    width: 5.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    @extend .transition;
    position: relative;
    cursor: pointer;
    margin-right: 2.4rem;
    margin-bottom: 1rem;
    &:last-child {
      margin: 0;
    }
    .hidden-input {
      z-index: 1;
      &:checked {
        ~ .outer-border {
          opacity: 1;
          @include scale(1);
        }
        ~ .tickmark {
          opacity: 1;
        }
      }
    }
    .outer-border {
      display: block;
      @extend .absolute-pos;
      border: 0.2rem solid transparent;
      border-radius: 0.6rem;
      z-index: 0;
      opacity: 0;
      @extend .transition;
      @include scale(0.6);
    }
    .inner-box {
      display: inline-block;
      height: 4.2rem;
      width: 4.2rem;
      border-radius: 0.4rem;
      background-color: transparent;
      @extend .transition;
    }
    .tickmark {
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%, -50%);
      line-height: 0;
      font-size: 2.1rem;
      color: $white;
      z-index: 0;
      @extend .transition;
      opacity: 0;
    }
  }
}

.logo-cropping-block {
  min-height: 35rem;
  text-align: center;
  @include bp(sm-max) {
    min-height: inherit;
    padding-bottom: 10rem;
  }
  .crop-area {
    height: 23rem;
    background-color: $grey10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    @include bp(sm-max) {
      height: 20rem;
    }
  }
  .cropped-logo {
    display: flex;
    justify-content: center;
    margin-top: 3.3rem;
    img {
      display: block;
    }
  }
}

.MuiTooltip-popper {
  &[data-popper-placement*="bottom"] {
    padding-top: 1.3rem;
    .MuiTooltip-tooltip {
      margin-top: 0 !important;
    }
  }
  &[data-popper-placement="bottom-end"] {
    margin-right: -1.5rem !important;
    .MuiTooltip-tooltip {
      &:before {
        left: auto;
        right: 1rem;
      }
    }
  }
  .MuiTooltip-tooltip {
    background: $white;
    border-radius: 0.3rem;
    box-shadow: 0 0.6rem 1.6rem 0 rgba($black, 0.15);
    padding: 0.8rem 1.6rem;
    color: $black1;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.012rem;
    font-weight: 400;
    max-width: 32.4rem;
    transition: none;
    &.MuiTooltip-tooltipPlacementTop {
      &::before {
        top: auto;
        bottom: 7px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      top: 0.7rem;
      box-shadow: 0 0 1rem 0 rgba($black, 0.15);
      height: 1.2rem;
      width: 1.2rem;
      background: $white;
      z-index: -1;
      display: block;
      transition: none;
    }
  }
}

.custom-tag {
  padding: 0.7rem 0.8rem;
  font-size: 1rem;
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: $white;
  background-color: $grey4;
  border-radius: 3rem;
  &.is-yellow {
    background-color: $amber;
  }
  &.is-purple {
    background-color: $lightPurple;
    color: $black;
  }
  &.purple {
    background-color: #6200ea!important;
  }

  &.yellow {
    background-color: #ffab00!important;
  }
  &.green {
    background-color: #00c853!important;
  }
}

.button-grouping {
  background-color: #ecedf0;
  border-radius: 0.8rem;
  display: flex;
  .inner-btn {
    width: 5.2rem !important;
    border-radius: 0.8rem;
    @extend .transition;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 3.5rem;
    min-width: inherit !important;
    padding: 0 !important;
    em {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &::before {
        color: $grey5;
      }
    }
    &:hover {
      background-color: transparent;
      border: 1px solid $purple2 !important;
    }
    &.active {
      background-color: $lightPurple;
      &:hover {
        background-color: $lightPurple;
      }
      .stroke {
        stroke: $black1;
      }
      em {
        &::before {
          color: $black1;
        }
      }
    }
    .stroke {
      @extend .transition;
      stroke: $grey5;
    }
  }
}

.progress-with-bg {
  position: relative;
  display: inline-flex;
  .bg-progress {
    @extend .absolute-pos;
    &.is-purple {
      .MuiCircularProgress-circle {
        stroke: rgba($purple, 0.15);
      }
    }
    .MuiCircularProgress-circle {
      stroke: $grey8;
    }
  }
  .progress-val {
    @extend .absolute-pos;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.0rem;
    line-height: 1.6rem;
    color: $purple;
    font-weight: 500;
  }
  &.circle-open-down {
    .MuiCircularProgress-root {
      transform: rotate(135deg) !important;
    }
  }
}

.custom-textarea {
  background: $white;
  label {
    background: none;
  }
  textarea {
    padding-top: 0;
    margin-top: 1.6rem;
    @include custom-scroll;
  }
  .MuiInputBase-root {
    padding: 0.8rem 1.6rem;
    fieldset {
      legend {
        span {
          background-color: transparent;
          display: none;
        }
      }
    }
  }
  &.description {
    max-height: 9.2rem;
    @include custom-scroll;
    textarea {
      padding-top: 0;
    }
  }
  &.request-note {    
    textarea { 
      resize: auto;
    }
  }
}
.custom-textarea2 {
  resize: vertical;
  border-radius: 0.4rem;
  padding: 1.2rem 1.6rem;
  border: 1px solid $grey18;
  font-size: 1.4rem;
  @include line-height(14,20);
  @include custom-scroll;
  &.br-8 {
    border-radius: 0.8rem;
  }
  ::placeholder {
    color: $grey11;
  }
}

//custom search icon
.search-input-box {
  position: relative;  
  .MuiIconButton-root {
    display: none;
  }
  ::placeholder {
    color: $grey11 !important;
  }
  &::after {
    content: "\68";
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -12px;
    font-size: 2.4rem;
    color: $black1;
    cursor: pointer;
    font-family: "builder-enterprise" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.MuiStack-root {
  &.sm-height {
    .MuiChip-root {
      height: 2rem;
      padding: 0.2rem 0.8rem;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: inherit;
      .MuiChip-label {
        padding: 0;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
    }
  }
}

.switch-with-label {
  .MuiFormControlLabel-label {
    margin-left: 0.8rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.form-label {
  display: block;
  margin-bottom: 1rem;
}

// Rounded Goals button
.btn-goal-rounded {
  &.MuiButton-root {
    padding: 0.4rem 0.8rem;
    border-radius: 1.2rem;
    background-color: transparent;
    min-width: 4rem;
    height: 2.4rem;
    font-size: 1.2rem;
    border: 1px solid $purple2;
    @include line-height(12,16);    
    &:hover {
      background-color: $white;
    }
    .icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.4rem;
      &::before {
        font-size: 1.2rem;
      }
    }
  }
}

.btn-transparent {
  border: 1px solid $purple2 !important;
  background: transparent !important;
  padding: 0.8rem 2.4rem !important;  
  &:hover {
    background: #fbfbfb !important;
  }
}
.btn-white {
  border: 1px solid $purple10 !important;
  outline: 1px solid transparent;
  background: $white !important;
  padding: 0.8rem 2.4rem !important;  
  &:hover {
    background: $white !important;
    outline: 1px solid $purple2;
    border: 1px solid $purple2 !important;
  }
}

// Common button with hover, active, visited style
.is-icon-btn {
  @extend .transition;
  &:hover {
    background-color: $lightPurple !important;
    border-color: $purple2 !important;
  }  
}
.white-btn {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid $purple2;
  }
  &.is-hover-btn {
    &:hover {
      border-color: $purple4 !important;
    }
  }
}
.is-hover-btn {
  @extend .transition;
  &:hover, &:active, &:focus {
    border-color: $purple2 !important;
  }
  &.white {
    background: $white !important;
  }
}
.is-green-hover-btn {
  @extend .transition;
  &:hover, &:active, &:focus {
    border-color: $green1 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: rgba(255, 255, 255, 1);
}