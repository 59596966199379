.full-width {
  width: 100% !important;
}
.hidden {
  display: none !important;
}
.success-generic-box {
  max-width: 65.6rem;
  margin: 0 auto;
  background: $white;
  border-radius: 0.8rem;
  padding: 7.7rem 8.7rem;
  @include bp(lg-max) {
    padding: 6rem 7rem;
  }
  @include bp(sm-max) {
    padding: 5rem 1.6rem;
  }
  .success-title {
    margin-bottom: 4.4rem;
    h2 {
      margin-bottom: 1.3rem;
    }
  }
  .MuiButton-root {
    min-width: 18rem;
  }
  .icon {
    display: inline-block;
    margin-bottom: 1.8rem;
  }
  h2 {
    margin-bottom: 3.2rem;
    &:last-child {
      margin: 0;
    }
  }
  p {
    margin-bottom: 3.2rem;
    &:last-child {
      margin: 0;
    }
  }
  .primary-link {
    text-decoration: none;
    font-weight: 500;
    line-height: 2.5rem;
  }
}

.tooltip-wrapper {
  .tooltip-trigger {
    display: flex;
    .trigger-text {
      max-width: calc(100% - 2.2rem);
      @extend .truncated-text;
    }
    .tooltip-icon {
      margin-left: 0.8rem;
      color: $trueGrey;
      line-height: 0;
      cursor: pointer;
      font-size: 1.4rem;
      position: relative;
      &:hover {
        .tooltip-content {
          @include translate(-50%, 0rem);
          opacity: 1;
          visibility: visible;
          &.right-align {
            @include translate(0, 0rem);
          }
        }
      }
    }
    .tooltip-content {
      position: absolute;
      padding-top: 0.8rem;
      z-index: 1;
      top: 100%;
      font-size: 1.2rem;
      @include line-height(12, 16);
      letter-spacing: 0.01em;
      color: $black1;
      width: 32.4rem;
      left: 50%;
      font-style: normal;
      @include translate(-50%, 1rem);
      @extend .transition;
      opacity: 0;
      visibility: hidden;
      cursor: default;
      text-align: left;
      white-space: pre-wrap;
      @include bp(sm-max) {
        width: 20rem;
      }
      &.sm-width {
        width: 17.8rem;
      }
      &.right-align {
        left: auto;
        right: -1.5rem;
        @include translate(0, 1rem);
        &::before {
          left: auto;
          right: 1rem;
        }
      }
      &.reverse {
        top: auto;
        bottom: 100%;
        padding-top: 0;
        padding-bottom: 0.8rem;
        @include translate(-50%, -1rem);
        .content-inner {
          box-shadow: 0 -0.6rem 1.5rem -0.3rem rgba($black, 0.15);
        }
        &::before {
          top: auto;
          bottom: 0.2rem;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        top: 0.2rem;
        box-shadow: 0 0 1rem 0.1rem rgba($black, 0.15);
        height: 1.2rem;
        width: 1.2rem;
        background: $white;
        z-index: -1;
        display: block;
      }
      .content-inner {
        background: $white;
        border-radius: 0.3rem;
        box-shadow: 0 0.6rem 1.5rem -0.3rem rgba($black, 0.15);
        padding: 0.8rem 1.6rem;
      }
    }
  }
}

.custom-table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border: 0.1rem solid $grey2;
  border-radius: 1.2rem 1.2rem 0 0;
  background-color: $white;
  text-align: left;
  &.secondary {
    border-radius: 0.8rem;
    th {
      background: $grey7;
      height: 4rem;
      font-size: 1.2rem;
      @include line-height(12, 16);
      font-weight: 500;
      padding: 0 1.2rem;
      border-color: $grey2;
      text-transform: uppercase;
      color: $black1;
      letter-spacing: 0.1rem;
      border-right: 0;
      &:first-child {
        padding-left: 1.2rem;
        border-radius: 0.8rem 0 0 0;
        width: 72%;
      }
      &:last-child {
        border-radius: 0 0.8rem 0 0;
        width: 15rem;
        min-width: 15rem;
      }
      &.checkbox-col {
        width: 12rem;
        min-width: 12rem;
        text-align: center;
        @include bp(lg-max) {
          width: 15rem;
          min-width: 15rem;
        }
        @include bp(smd-max) {
          width: 10rem;
          min-width: 10rem;
        }
      }
      .sm-font {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        color: $grey4;
      }
    }
    td {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.014rem;
      padding: 0.25rem 1.2rem 0.2rem;
      border-right: 0;
      height: 4.5rem;
      &:first-child {
        padding-left: 1.2rem;
      }
      .MuiCheckbox-root {
        min-width: 3.6rem;
        width: 3.6rem;
        height: 3.6rem;
        img {
          max-width: 2rem;
          width: 2rem;
          height: 2rem;
        }
      }
      .be-info {
        color: $grey11;
        position: relative;
        top: 0.2rem;
        margin-left: 0.1rem;
        font-size: 1.4rem;
        cursor: pointer;
      }
    }
  }
  th {
    font-size: 2rem;
    line-height: 3.2rem;
    height: 8.6rem;
    border-bottom: 0.1rem solid $grey18;
    border-right: 0.1rem solid $grey18;
    padding: 0 1.9rem;
    font-weight: 500;
    background-color: $lightPurple;
    width: 20%;
    color: $black1;
    &:first-child {
      padding-left: 2.4rem;
      border-radius: 1.3rem 0 0 0;
    }
    &:last-child {
      border-radius: 0 1.3rem 0 0;
    }
    .price {
      display: block;
      color: $grey4;
      font-weight: 400;
      font-size: 1.6rem;
      @include line-height(16, 24);
    }
  }
  td {
    padding: 1.2rem 1.9rem;
    border-bottom: 0.1rem solid $grey18;
    font-size: 1.6rem;
    height: 7.2rem;
    @include line-height(16, 24);
    border-right: 0.1rem solid $grey18;
    span {
      color: $grey11;
    }
    a {
      font-weight: 600;
    }
    &:first-child {
      padding-left: 1.5rem;
    }
    // &:last-child {
    //   border-right: 0;
    // }
    .inner-title {
      font-weight: 500;
      margin-bottom: 0;
      em {
        position: relative;
        top: 2px;
        margin-left: 0.5rem;
      }
    }
    .be-tick {
      color: $purple1;
    }
    .check-icon {
      background-color: $green1;
      border-radius: 1rem;
      width: 2.8rem;
      height: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &.yellow {
        background-color: $amber;
      }
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
    &.lg-height {
      td {
        height: 7.2rem;
        vertical-align: middle;
      }
    }
  }
}

.custom-accordion {
  border: 0.1rem solid $grey2;
  background: $white;
  border-radius: 1.2rem;
  .MuiAccordion-root {
    box-shadow: none;
    color: $black;
    margin: 0;
    border-bottom: 0.1rem solid $grey2;
    &::before {
      display: none;
    }
    &:first-of-type {
      border-radius: 1.2rem 1.2rem 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 1.2rem 1.2rem;
      border-bottom: 0;
    }
    &.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionSummary-root {
      padding: 2.35rem 1.6rem;
      background: transparent;
      position: relative;
      min-height: inherit;
      @include bp(lg-max) {
        padding: 1.8rem 1.6rem;
      }
      @include bp(sm-max) {
        padding: 1.5rem 1.2rem;
      }
      &:after {
        border: 0;
        transform: none;
        content: '\6f';
        font-family: $enterpriseFont;
        padding: 0;
        color: $black;
        line-height: 0.6;
        font-size: 2.4rem;
        @extend .transition;
        @include bp(lg-max) {
          font-size: 2rem;
        }
        @include bp(sm-max) {
          font-size: 1.6rem;
        }
      }
      &.Mui-expanded {
        min-height: inherit;
        &::after {
          @include rotate(180deg);
        }
      }
    }
    .MuiAccordionSummary-content {
      margin: 0;
      h6 {
        margin: 0;
        .country-label {
          display: block;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: $trueGrey;
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 1.6rem 1.6rem;
    }
  }
}

.secondary-accordion {
  .MuiAccordion-root {
    margin-bottom: 1.6rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.15);
    border: 0;
    padding: 2.4rem 3rem;
    color: $black1;
    &::before {
      display: none;
    }
    &:first-of-type {
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }
    &:last-of-type {
      margin-bottom: 0;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
    .MuiAccordionSummary-root {
      min-height: inherit;
      padding: 0;
      &.Mui-expanded {
        .left-heading {
          .icon {
            transform: rotate(-90deg);
          }
        }
        .accordion-heading {
          .right-btns {
            display: flex;
            @include bp(sm-max) {
              margin: 1rem 0;
            }
          }
        }
        // .roles-desc {
        //   position: static;
        //   margin-left: 4.4rem;
        //   @include bp(lg-max) {
        //     margin-left: 3rem;
        //     width: 100%;
        //   }
        //   @include bp(sm-max) {
        //     display: block;
        //     margin-left: 0;
        //   }
        //   p {
        //     -webkit-line-clamp: inherit;
        //   }
        // }
      }
      .roles-desc {
        width: 60%;
        @include bp(sm-max) {
          width: 100%;
        }
        p {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 0.012rem;
          color: $trueGrey;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @include bp(sm-max) {
          }
        }
      }
      .MuiAccordionSummary-content {
        margin: 0;
        flex-wrap: wrap;
      }
    }
    .MuiAccordionDetails-root {
      padding: 4rem 0 0 0;
    }
  }
}

.faq-section {
  h2 {
    margin-bottom: 2.5rem;
  }
  .contact-block {
    margin-top: 3.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .faq-accordion {
    background: transparent;
  }
}

.plan-selection-row {
  margin: 0 -1.5rem -3rem;
  display: flex;
  flex-wrap: wrap;
  .plan-selection-col {
    padding: 0 1.5rem;
    width: 25%;
    margin-bottom: 3rem;
    @include bp(lg-max) {
      width: 50%;
    }
    @include bp(sm-max) {
      width: 100%;
    }
    .enterprise-plan-card {
      height: 100%;
      @include bp(sm-max) {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.internal-section-title {
  margin-bottom: 1.6rem;
  h4 {
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h6 {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    font-size: 1.6rem;
    @include line-height(16, 24);
    color: $trueGrey;
  }
}

.member-permission-wrapper {
  .permission-form-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > .MuiButton-root {
      width: 9.8rem;
      @include bp(xs-max) {
        width: 100%;
      }
    }
    .member-input {
      position: relative;
      width: calc(100% - 11rem);
      @include bp(xs-max) {
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .MuiInputBase-root.sm-input {
        .MuiInputBase-input {
          padding-right: 10rem;
        }
      }
      .MuiButton-root {
        &.sm-btn {
          margin-top: 0.2rem;
        }
      }
      .menu-with-arrow {
        position: absolute;
        right: 0.1rem;
        top: 0;
      }
    }
  }
  .users-list {
    max-height: 16rem;
    overflow: auto;
    @include custom-scroll;
    @include bp(sm-max) {
      padding-left: 0;
      margin-top: 1.6rem;
    }
    .menu-with-arrow {
      width: 9.8rem;
      .MuiButtonBase-root.sm-btn {
        height: 3.2rem;
      }
    }
    .user-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      width: calc(100% - 9.8rem);
      .dp {
        display: inline-flex;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          @extend .cover-fit;
        }
      }
      .name {
        width: calc(100% - 3.2rem);
        padding: 0 0.8rem;
        font-size: 1.4rem;
        @extend .truncated-text;
      }
    }
  }
}

.boxed-title-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.sm {
    .boxed-title {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0 0.6rem;
      margin-bottom: 0;
    }
  }
  .boxed-title {
    display: inline-block;
    font-weight: 500;
    font-size: 4.8rem;
    @include line-height(48, 60);
    color: $black1;
    background: $white;
    border: 0.2rem solid $black;
    border-top: 0;
    padding: 0 1.5rem 0 0.6rem;
    margin-bottom: 0.5rem;
    &:first-child {
      border-top: 0.2rem solid $black;
    }
    @include bp(xlg-max) {
      font-size: 4rem;
    }
    @include bp(sm-max) {
      font-size: 3rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.swiper {
  &.secondary-swiper {
    .swiper-pagination {
      margin-top: 1.6rem;
      position: relative;
      z-index: 1;
      top: auto;
      bottom: auto;
      .swiper-pagination-bullet {
        height: 0.4rem;
        width: 0.4rem;
        background-color: $grey10;
        margin-right: 0.4rem;
        &:last-child {
          margin-right: 0;
        }
        &.swiper-pagination-bullet-active {
          width: 0.4rem;
          background-color: $purple1;
        }
      }
    }
  }
  .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-pagination-bullet {
      margin: 0;
      height: 0.6rem;
      width: 0.6rem;
      margin-right: 0.6rem;
      @extend .transition;
      background-color: rgba($purple1, 0.2);
      opacity: 1;
      border-radius: 2rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.swiper-pagination-bullet-active {
        width: 1.4rem;
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  height: 3.6rem;
  width: 3.6rem;
  min-width: 3.6rem;
  top: 44%;
  border-radius: 50%;
  background: $white;
  opacity: 0.6;
  box-shadow: 0 0.6rem 1.5rem -0.3rem rgba($black, 0.15);
  &.sm {
    height: 1.6rem;
    width: 1.6rem;
    min-width: 1.6rem;
    &::after {
      font-size: 0.8rem;
    }
  }
  &:after {
    font-family: $enterpriseFont;
    content: '\77';
    font-size: 1.2rem;
    color: $black1;
  }
}

.swiper-button-prev {
  left: 1.4rem;
  right: auto;
  &::after {
    @include rotate(180deg);
  }
}
.swiper-button-next {
  right: 1.4rem;
  left: auto;
}

.swiper-inline-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-wrapper {
    display: inline-flex;
    align-items: center;
  }
  .swiper-pagination {
    position: static;
    margin: 0 1rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    opacity: 1;
    margin: 0;
    transform: none;
  }
}

.space-list-slide {
  .space-slide-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    .space-slide-col {
      padding: 0 1.3rem;
      width: 33.33%;
      .card-template {
        margin: 0;
        height: 100%;
      }
    }
  }
}

// Square three dot btn
.square-btn-three-dots {
  width: 4.8rem !important;
  min-width: 4.8rem !important;
  height: 4.8rem !important;
  .icon {
    display: flex;
    font-size: 2rem !important;
  }
}

// three dot icon button
.three-dot-btn {
  border: 0;
  background: transparent !important;
  padding: 0;
  min-width: auto !important;
  width: 2.8rem !important;
  height: 2.8rem !important;
  .icon {
    height: inherit;
    width: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      font-weight: 500 !important;
      font-size: 2rem;
      color: $trueGrey !important;
    }
  }
  &:hover,
  &.Mui-focusVisible,
  &:active {
    background-color: $lightPurple !important;
    @extend .transition;
    .icon {
      &::before {
        color: $black !important;
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
}

// Three dot menu paper
.three-dot-menu-paper {
  .MuiPaper-root {
    box-shadow: 0px 2px 4px 0px #00000026 !important;
    border-radius: 0.8rem;
    min-width: 13rem;
    margin-top: 0.5rem;
    ul.MuiList-root {
      padding: 0 !important;
      li {
        padding: 1.2rem 1.6rem !important;
        border: 1px solid $grey13;
        padding: 0.9rem 1.6rem !important;
        img,
        .icon {
          margin-right: 0.8rem;
          display: flex;
        }
        &:hover {
          background: $lightPurple !important;
          border-color: $lightPurple !important;
        }
      }
    }
  }
  &.user-journey-paper {
    ul.MuiList-root {
      li {
        font-size: 1.2rem;
        em {
          font-size: 1.6rem;
          color: $black;
        }
      }
    }
  }
}

.filter-by-dropdown-menu {
  .MuiPaper-root {
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    border-radius: 0.8rem;
    min-width: 30rem !important;
    margin-top: 0.5rem;
    max-height: inherit !important;
    ul.MuiList-root {
      padding: 0 !important;
      li {
        padding: 0 !important;
        display: block;
        .head {
          padding: 1.4rem 1.2rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.4rem;
          @include line-height(14, 20);
          border-bottom: 1px solid $grey6;
          .left {
            .title {
              font-weight: 500;
            }
          }
          .right {
            .reset-link {
              color: $purple;
              @extend .transition;
              &:hover {
                color: $purple1;
                text-decoration: underline;
              }
            }
            .be-close {
              color: $grey11;
              width: 1.2rem;
              height: 1.2rem;
              margin-left: 2.4rem;
              &::before {
                font-size: 1rem;
                color: $grey11;
                font-weight: bold !important;
              }
              &:hover {
                &::before {
                  color: $black;
                }
              }
            }
          }
        }
        .body-content {
          padding: 1.2rem;
          .inner-wrap {
            .title {
              font-size: 1.4rem;
              @include line-height(14, 20);
              display: flex;
              align-items: center;
              margin: 0 0 1.2rem 0;
              .be-buildcard-box {
                margin-right: 0.6rem;
                height: 1.4rem;
                width: 1.4rem;
                display: flex;
              }
            }
            ul {
              margin: 0;
              padding: 0 0 0 1.6rem;
              li {
                display: flex;
                align-items: center;
                margin: 0;
                margin: 0 0 0.6rem;
                position: relative;
                color: $black1;
                font-size: 1.4rem;
                .count {
                  color: $grey4;
                }
                .dot {
                  &.sx {
                    margin-right: 0.6rem;
                  }
                  &.status-running {
                    background-color: $purple1;
                  }
                  &.status-pending-approval {
                    background-color: $amber;
                  }
                  &.status-ready-for-kickoff {
                    background-color: $purple;
                  }
                  &.status-delivered {
                    background-color: $green1;
                  }
                }
                .MuiCheckbox-root {
                  height: 1.6rem;
                  width: 1.6rem;
                  min-width: 1.6rem;
                  margin-right: 1rem;
                  border-radius: 0.2rem;
                  border: 0;
                  background: transparent;
                  padding: 0;

                  img {
                    margin: 0;
                    width: 16px;
                    height: 16px;
                  }
                  .MuiTouchRipple-root {
                    display: none;
                  }
                }
                .dot {
                  width: 0.8rem;
                  height: 0.8rem;
                  margin-right: 0.6rem;
                }
              }
            }
            .archived-block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-top: 0.1rem solid $grey6;
              padding-top: 1rem;
              margin-top: 1.5rem;
              .name {
                font-size: 1.4rem;
                color: $black1;
                display: flex;
                align-items: center;

                em {
                  display: flex;
                  font-size: 1.8rem;
                  align-items: flex-end;
                  margin-right: 0.8rem;
                }
              }
              .switch-archived {
                .MuiButtonBase-root {
                  height: 1.4rem;
                  background-color: $white;
                  border: 0;
                  padding: 0;
                }
              }
              &.only-archived {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
              }
            }
          }
        }
        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }
}

.no-search-results {
  text-align: center;
  border: 0.1rem dashed $grey2 !important;
  padding: 6rem 0 !important;

  @include bp(lg-max) {
    padding: 15rem 0;
  }
  @include bp(sm-max) {
    padding: 8rem 0;
  }
  &.is-flexbox {
    height: calc(100vh - 22.8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45rem;
    padding: 0;
    background-color: $white;
  }
  .icon {
    display: inline-block;
    line-height: 0;
    margin-bottom: 3.4rem;
    &.sm {
      margin-bottom: 0.8rem;
      img {
        max-width: 7.5rem;
      }
    }
  }
  h4 {
    margin: 0;
  }
  h6 {
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    color: $trueGrey;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 0;
  }
}

.no-data-wrapper {
  text-align: center;
  padding: 3.2rem 1.5rem;
  background: rgba($white, 0.7);
  border: 0.1rem dashed $grey2;
  @extend .be-radius;

  &.secondary {
    margin: 0 auto;
    padding: 2.4rem;
    border: 1px dashed var(--blue-gray-3, #dfe0e6);
    background: #fff;
    width: 100%;
    .MuiButton-root {
      width: auto;
    }
    &.fixed-height {
      height: 32.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &.no-border {
    border: 0 !important;
    background: transparent;
    padding: 5.5rem 0;
    &.xs-pad {
      padding: 0.8rem 0;
    }
    &.sm-pad {
      padding: 2.4rem 0;
    }
    .thumb {
      margin-bottom: 0.8rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.is-purple {
    padding: 4rem 1.5rem;
    border-color: $purple2;
    border-radius: 0;
    &.sm-pad {
      padding: 2rem 0;
    }
    &.lg-pad {
      padding: 6.4rem 0;
    }
    .thumb {
      margin-bottom: 1rem;
      &.mini {
        max-width: 80rem !important;
      }
    }
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .MuiButton-root {
      width: auto;
      padding: 0 1.6rem;
    }
  }

  .thumb {
    display: inline-flex;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 3.2rem;
    color: $black1;
    margin: 0 0 0.4rem 0;
  }
  h6 {
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 0;
    }
    &.sm {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  p {
    font-size: 1.6rem;
    @include line-height(16, 24);
    color: $trueGrey;
    margin: 0 0 2rem 0;
    &.sm-font {
      color: $trueGrey;
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .MuiButton-root {
    width: 18rem;
    max-width: 100%;
    font-size: 1.4rem;
    margin: 0 auto;
    background-color: $lightPurple;
    &:hover {
      background-color: $grey3;
    }
    &.sm-width {
      width: 13.6rem;
    }
    &.w-auto {
      width: auto;
      padding: 0.8rem 1.7rem;
    }
  }
  &.meetings {
    padding: 2.1rem 1.5rem;
    border: 0;
    background: transparent;
  }
  &.no-leads {
    transform: translate(0, 10%);
    .thumb {
      width: 4rem;
      height: 4rem;
    }
    h6 {
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  &.spaces {
    padding: 5.6rem !important;
  }
  &.no-member {
    padding: 5.6rem !important;
    .thumb {
      margin-bottom: 0.4rem;
    }
    &.found {
      .thumb {
        margin-bottom: 1.6rem;
      }
    }
  }
  &.meeting-transcript {
    padding: 10.2rem;
    h4 {
      font-size: 2rem !important;
      margin-bottom: 0.4rem !important;
    }
    p {
      font-size: 1.6rem !important;
    }
  }
  &.no-allocation-yet {
    border: 0;
    background: $white;
    padding-top: 7.8%;
  }
}

.page-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  //margin-bottom: 1.6rem;
  .left-filter {
    @include bp(smd-max) {
      width: 100%;
    }
    .count {
      display: inline-block;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $trueGrey;
      font-weight: 400;
      letter-spacing: 0.01em;
      &.filter-title {
        font-weight: 500;
      }
      &.lg {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
  .right-filter {
    display: flex;
    flex-wrap: wrap;
    &.ideaboard {
      position: relative;
      top: 0.2rem;
      @include bp(smd-max) {
        top: auto;
        .MuiButton-root {
          margin-left: 0 !important;
          margin-right: 1.6rem !important;
        }
      }
    }
    @include bp(lg-max) {
      margin-top: 1rem;
    }
    @include bp(smd-max) {
      width: 100%;
    }
    > * {
      margin-right: 1.6rem;
      @include bp(sm-max) {
        margin-right: 1rem;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }
    .form-group {
      width: 30.4rem;
      margin-bottom: 0;
      @include bp(sm-max) {
        width: 100%;
        margin: 0 0 1.5rem;
        order: -1;
      }
      &.sort-by {
        width: auto;
        font-size: 1.4rem;
        line-height: 2rem;
        .dropdown-text {
          margin-right: 0.7rem;
        }
        .MuiOutlinedInput-root {
          &.borderless-dropdown {
            .MuiSelect-select {
              font-weight: 500;
            }
          }
        }
      }
    }
    .MuiButton-root {
      &.purple-btn {
        min-width: initial;
        width: auto;
        height: 3.6rem !important;
        line-height: 3.6rem;
        font-size: 1.4rem;
      }
      &.btn-full {
        min-width: max-content !important;
      }
      &.is-icon-btn {
        &::before {
          //left: 0.2rem;
          position: relative;
        }
        &.btn-archive {
          background: $white;
          width: 3.6rem !important;
          height: 3.6rem;
          border: 1px solid $grey;
          .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &.be-calendar-plus {
        background: none;
        border: none;
        margin-right: 2.2rem;
        &::before {
          font-size: 3.2rem;
        }
      }
    }
    &.playground {
      position: relative;
      top: 0px;
    }
  }

  .justify-right {
    justify-content: flex-end;
    align-items: center;
  }
  .search-input-wrap {
    border-color: $grey;
    .be-search {
      color: $black1;
    }
    ::placeholder {
      color: $grey11 !important;
    }
    .search-icon-wrap {
      cursor: pointer;
      img {
        width: 2rem;
        height: 2.2rem;
      }
    }
  }
}

.payment-waiting-strip {
  background: $purple3;
  border: 0.2rem solid $purple3;
  @extend .be-radius;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 2rem 2.4rem 2rem 11.9rem;
  @include bp(lg-max) {
    padding: 1.5rem 1.5rem 1.5rem 10rem;
  }
  @include bp(sm-max) {
    padding: 1.5rem;
  }
  .icon {
    position: absolute;
    left: 2.7rem;
    bottom: -0.2rem;
    line-height: 0;
    @include bp(lg-max) {
      left: 2rem;
    }
    @include bp(sm-max) {
      left: auto;
      right: 1.5rem;
    }
  }
  .content {
    width: calc(100% - 32.5rem);
    padding-right: 1rem;
    color: $white;
    font-size: 2rem;
    @include line-height(20, 32);
    font-weight: 500;
    @include bp(xlg-max) {
      font-size: 1.8rem;
    }
    @include bp(smd-max) {
      width: 100%;
    }
    &.full-width {
      width: 100%;
      padding-right: 0;
    }
  }
  .btn-wrap {
    display: flex;
    flex-wrap: wrap;
    @include bp(smd-max) {
      width: 100%;
      margin-top: 0.5rem;
    }
    @include bp(xs-max) {
      flex-direction: column;
      max-width: calc(100% - 8rem);
    }
    .MuiButton-root {
      margin-right: 1.4rem;
      min-width: 15.4rem;
      &:last-child {
        margin: 0;
      }
      @include bp(xs-max) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.or-block {
  margin: 2.4rem 0;
  text-align: center;
  position: relative;
  z-index: 0;
  color: $grey4;
  &:after {
    content: '';
    height: 0.1rem;
    display: block;
    background: $grey;
    z-index: -1;
    position: absolute;
    top: calc(50% - 0.05rem);
    left: 0;
    right: 0;
  }
  .or-text {
    padding: 0 0.8rem;
    background: $white;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.payment-status-block {
  padding: 3.3rem 3.4rem 2.9rem 4.5rem;
  background-color: $purple2;
  @extend .be-radius;
  color: $white;
  @include bp(lg-max) {
    padding: 3rem 2.5rem;
  }
  .status-row {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    @include line-height(16, 24);
    h2 {
      margin-bottom: 1.6rem;
      letter-spacing: 0.01em;
    }
    .status-right {
      .price {
        @extend .h2;
        letter-spacing: 0.01em;
      }
    }
  }
  .status-btn {
    display: flex;
    justify-content: flex-end;
    @include bp(lg-max) {
      margin-top: 1.5rem;
    }
    .MuiButton-root {
      &.xs-btn {
        height: 4rem;
        width: 19.6rem;
      }
    }
  }
}

.plan-wise-detail-wrapper {
  border: 0.1rem solid $grey2;
  box-shadow: 0 0.8rem 2rem rgba(64, 0, 152, 0.04);
  border-radius: 0 0 0 2rem;
  background: $white;
  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 0.4rem;
  }
  .primary-link {
    font-size: 1.4rem;
    letter-spacing: 0.01em;
  }
  .plan-detail-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $lightPurple;
    position: relative;
    padding: 3.1rem 3.4rem 4rem 20.2rem;
    border-top: 0.8rem solid $purple1;
    overflow: hidden;
    z-index: 0;
    @include bp(lg-max) {
      padding: 3rem 2.5rem 3rem 15rem;
    }
    @include bp(sm-max) {
      padding: 2.5rem;
    }
    &::before {
      content: '';
      height: 33.7rem;
      width: 33.7rem;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      bottom: 2.9rem;
      left: -21rem;
      z-index: -1;
      @include bp(sm-max) {
        left: -18rem;
        bottom: auto;
        top: -20rem;
      }
    }
    .plan-img {
      position: absolute;
      left: 3.5rem;
      top: 4.8rem;
      line-height: 0;
      @include bp(lg-max) {
        left: 1rem;
      }
      @include bp(sm-max) {
        position: static;
        margin-bottom: 1rem;
      }
      img {
        width: 12rem;
      }
    }
    .plan-info {
      @include bp(sm-max) {
        width: 100%;
      }
      h2 {
        margin-bottom: 1.8rem;
      }
    }
    .plan-right {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include bp(sm-max) {
        width: 100%;
        text-align: left;
        margin-top: 3rem;
      }
      .plan-validity {
        .validity {
          display: block;
        }
      }
      .plan-links {
        @extend .no-bullets;
        display: flex;
        justify-content: flex-end;
        @include bp(sm-max) {
          justify-content: flex-start;
          margin-top: 2rem;
        }
        li {
          margin-right: 4rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .plan-credit-outer {
    padding: 3.2rem 3.4rem 4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include bp(lg-max) {
      padding: 3rem 2.5rem;
    }
    @include bp(sm-max) {
      padding: 2.5rem;
    }
    .left-credit {
      .available-credit {
        display: block;
      }
      h6 {
        margin-bottom: 0.8rem;
      }
    }
    .topup-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      .credit-used {
        color: $grey4;
        display: block;
      }
    }
    .credit-utilization-outer {
      width: 100%;
      margin-top: 1.6rem;
      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $grey4;
        margin-top: 0.8rem;
      }
    }
  }
}

.hoverable {
  transition: all ease-in 0.2s;
  &:hover,
  &:focus,
  &:active {
    border-color: $purple2 !important;
    box-shadow: 0 0.4rem 1.6rem 0 #00000026 !important;
  }
}

.info-tooltip {
  &.list-buildcards {
    .MuiTooltip-tooltip {
      max-height: 22rem;
      overflow-x: hidden;
      overflow-y: auto;
      @include custom-scroll;
    }
  }

  position: relative;
  &.bottom-arrow {
    content: '';
    position: absolute;
    display: block;
    width: 0px;
    left: 50%;
    bottom: 0;
    border: 1rem solid transparent;
    border-bottom: 0;
    border-top: 1rem solid #5494db;
    transform: translate(-50%, calc(100% + 5px));
  }
  &.donut-chart-tooltip {
    left: -15.5rem !important;
    top: -10rem !important;
  }
  &.fixed-width {
    max-width: 22.1rem;
  }
  &.dashboard-space-name,
  &.dashboard-projects-tab,
  &.dashboard-card-name {
    max-width: 26rem;
  }
  &.dashboard-more-dropdown {
    max-width: 17rem;
  }
  .MuiTooltip-tooltip {
    width: auto !important;
    min-width: auto !important;
    &::before {
      visibility: hidden;
      opacity: 0;
      box-shadow: none;
      z-index: 0;
    }
    .MuiTooltip-arrow {
      color: #fff;
    }
  }
  &.archive {
    .MuiTooltip-tooltip {
      right: 1.2rem;
    }
  }
  &.has-list-items {
    .tooltip-content > span {
      text-align: left !important;
    }
    .MuiTooltip-tooltip:before {
      box-shadow: none;
      top: auto;
      bottom: 0.8rem;
      opacity: 1;
      visibility: visible;
      z-index: 0;
    }
  }
  &.capt-first-letter {
    .tooltip-content {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  &.stack-menu {
    &[data-popper-placement='bottom-end'] {
      .MuiTooltip-tooltip {
        &::before {
          top: 0.7rem !important;
          bottom: auto !important;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &[data-popper-placement='top-end'] {
      .MuiTooltip-tooltip {
        margin-right: -1rem;
        &::before {
          bottom: 0.7rem !important;
          top: auto !important;
          visibility: visible;
          opacity: 1;
          left: auto;
          right: 0;
        }
      }
    }
    .MuiTooltip-tooltip {
      width: 21.6rem !important;
      max-width: 21.6rem !important;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15) !important;
      padding: 1.6rem;

      .purple-chips {
        flex-flow: column;
        .MuiChip-root {
          border-radius: 3.2rem;
          border: 1px solid $grey16;
          background: $grey15;
          padding: 0.2rem 0.8rem;
          margin: 0 0 1.4rem;
          height: auto;
          display: inline-flex;
          width: auto;
          max-width: min-content;
          &:last-child {
            margin: 0;
          }
          .MuiChip-label {
            padding: 0;
            font-size: 1.2rem;
            font-weight: 500;
            color: $black1;
            @include line-height(12, 16);
            letter-spacing: 0.012rem;
            display: block;
            max-width: 18rem;
            @extend .truncated-text;
            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  &.bottom-end {
    margin-right: 0 !important;
  }
  &.request-tooltip {
    width: 23rem;
  }

  &.approver-tooltip {
    max-width: 35.2rem;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  &.change-request-tooltip {
    .MuiTooltip-tooltip {
      max-width: 40.6rem;
    }
  }
  &.new-request-tooltip {
    a {
      font-size: 1.4rem;
      display: inline-flex;
      align-items: baseline;
      color: $purple4;
      margin-bottom: 0.3rem;
      em {
        font-size: 1rem;
        display: inline-flex;
        margin-left: 0.7rem;
      }
    }
    .MuiChip-root {
      height: 2rem;
      padding: 0;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.6rem 0 0.8rem;
      .MuiChip-label {
        padding: 0 0.8rem;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
    }
  }
  &.credits-required-info-tooltip {
    .topups-info {
      width: 30rem;
      tr {
        th {
          font-size: 1.4rem;
          font-weight: 500;
          color: #333;
          text-align: left;
          padding: 0.5rem 0rem 0.8rem;
          border-bottom: 0.1rem solid $grey2;
          &:last-child {
            text-align: right;
          }
        }
        td {
          font-size: 1.4rem;
          font-weight: 400;
          color: $dark-grey;
          padding: 0.5rem 0;
          &:last-child {
            text-align: right;
          }
        }
        // &:last-child {
        //   td {
        //     border-top: 0.1rem solid $grey2;
        //     font-size: 1.4rem;
        //     font-weight: 500;
        //     padding-top: 1rem;
        //     color: $black1;
        //   }
        // }
      }
    }
  }
}
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    &::before {
      transition: none !important;
    }
  }
}
.B-loading-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  z-index: 1400;
  //background-color: rgba(0, 0, 0, 0.2);
  background: rgba(248, 249, 250, 0.68);
}
// Alert messages
.alert-message-box {
  // &.MuiPaper-root {
  position: relative;
  left: 2rem !important;
  bottom: 1rem !important;
  background: $white !important;
  border-radius: 0.8rem !important;
  padding: 0.2rem 1.6rem !important;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px #00000026;

  #notistack-snackbar {
    max-width: 40rem;
    padding: 1.12rem 0;
  }
  &.is-success {
    border: 1px solid #00c853 !important;
    .toast-message-success {
      padding-left: 0 !important;
      color: $black1 !important;
      font-size: 1.6rem !important;
      font-weight: 400;
      line-height: 2.4rem;
      display: flex;
      &::before {
        background: url('../images/check-success-rounded.svg') 0 0 / cover
          no-repeat;
        width: 2rem !important;
        height: 2rem !important;
        display: inline-block;
        content: '';
        background-size: 1.9rem;
      }
    }
  }
  &.is-error {
    border: 1px solid #d50000 !important;
    .toast-message-error {
      padding-left: 0 !important;
      color: $black1 !important;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      display: flex;
      &::before {
        background: url('../images/close-remove-cross-circle.svg') 0 0 / cover
          no-repeat;
        width: 2rem !important;
        height: 2rem !important;
        display: inline-block;
        content: '';
        background-size: 1.9rem;
      }
    }
  }
  .MuiAlert-icon {
    padding: 0;
    margin: 0;
    width: 2.1rem;
    height: 2.1rem;
  }
  .MuiAlert-message {
    padding: 0.2rem 0 0 0;
    margin: 0 2rem 0 0.8rem;
    max-width: 50rem;
    color: $black1;
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    // @extend .truncated-text;
  }
  &.message {
    max-width: 50rem;
    color: $black1;
    font-size: 1.4rem;
    font-weight: 400;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    display: flex;
    align-items: center;
    .be-close {
      display: flex;
      padding-right: 0.6rem !important;
      &:hover {
        cursor: pointer;
      }
    }
    .be-close-dark {
      display: flex;
      height: 2rem;
      width: 2rem;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      &::before {
        font-size: 1rem;
        color: $grey4;
      }
    }
    .be-close-dark {
      display: flex;
      height: 2rem;
      width: 2rem;
      align-items: center;
      justify-content: center;
      &::before {
        font-size: 1.2rem;
        color: $grey4;
      }
    }
  }
  .MuiAlert-action {
    padding: 0;
    .MuiButtonBase-root {
      width: 2rem;
      height: 2rem;
      svg {
        color: $black1;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.MuiAutocomplete-popper {
  border: 1px solid $grey8 !important;
  box-shadow: 0px 2px 4px 0px #00000026;
  border-radius: 0.4rem;
  .MuiPaper-root {
    .MuiAutocomplete-noOptions {
      padding: 0.9rem 1.6rem;
    }
    .MuiAutocomplete-listbox {
      padding: 0 !important;
      @include custom-scroll;
    }
  }
}
.MuiMenu-root {
  .MuiPaper-root {
    @include custom-scroll;
  }
}
// Select carrot icon
.MuiAutocomplete-root {
  .MuiInputBase-root {
    .MuiAutocomplete-endAdornment {
      top: 50%;
    }
  }
}

.select-goal-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .left-side {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1.6rem;
    .MuiStack-root {
      //margin-bottom: .8rem;
      display: flex;
      .MuiChip-root {
        display: flex;
      }
    }
  }
  .right-side {
    display: block;
    //width: calc(100% - 4.2rem);
    flex-direction: column;
    align-items: flex-start;

    @include bp(sm-max) {
      display: block;
      width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.tag-wrapper {
  .MuiStack-root {
    display: block;
    .MuiChip-root {
      margin: 0 1.6rem 0.8rem 0;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.012rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.MuiAutocomplete-listbox {
  //border: 1px solid $grey8;
  .custom-tag {
    padding: 0.4rem 0.8rem;
    border: 0;
    background: $lightPurple;
    cursor: pointer;
    .MuiChip-label {
      padding: 0;
      color: $black1;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6rem;
      letter-spacing: 0.012rem;
      text-transform: none;
      max-width: 20rem;
      @extend .truncated-text;
    }
  }
}

// Sort by select menu
.sorting-menu-item {
  width: 18rem;
  padding: 1.2rem 1.6rem !important;
  background-color: $white;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.6rem !important;
  letter-spacing: 0.012rem;
  border-left: 1px solid $grey6;
  border-right: 1px solid $grey6;
  @extend .truncated-text;
  display: block !important;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
  &.Mui-selected {
    background-color: $lightPurple !important;
  }
  &.add-ellipses {
    @extend .truncated-text;
  }
  &.w-auto {
    width: auto !important;
  }
  span {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.t-none {
    text-transform: none !important;
  }
}
.MuiMenu-root {
  .MuiMenu-paper.MuiPaper-rounded {
    border-radius: 0.8rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) !important;
    ul.MuiList-root {
      padding: 0 !important;
      &.select-state-dropdown-popover {
        max-width: 48rem;
      }
      &.sort-with-tick {
        width: 12.4rem;
        li {
          width: inherit;
          > span:first-child {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            text-transform: capitalize;
          }
          .be-tick {
            color: $purple1;
            width: 1.6rem;
            height: 1.6rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    &.timezone-menu {
      max-height: 26.5rem;
      margin-top: 0.4rem;
    }
  }
}

.empty-tags-wrapper {
  text-align: center;
  .empty-icon {
    display: inline-block;
    margin-bottom: 0.6rem;
    &.goal {
      img {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
  .empty-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    &.grey {
      color: $trueGrey;
      margin-bottom: 0.8rem;
    }
  }
  .align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Tags dropdown list
.taglist-menu {
  .MuiMenu-paper {
    max-width: 22.4rem;
    max-height: 28rem;
    @include custom-scroll;
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    .MuiList-root {
      .selected-tags {
        padding: 0.8rem 1.6rem 0.4rem 1.6rem;
      }
    }
  }
  .MuiMenu-list {
    .selected-tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid $grey2;
      .custom-tag {
        display: inline-flex;
        margin: 0 0.6rem 0.6rem 0;
      }
    }
  }
  &.cards {
    .MuiPaper-root {
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15) !important;
      .MuiList-root {
        padding: 0;
        .empty-tags-wrapper {
          padding: 1.2rem 1.6rem 1.2rem 1.6rem;
          text-align: left;
          border-bottom: 1px solid $grey2;
          .empty-text {
            font-weight: 400;
            color: $grey11;
            font-size: 1.2rem;
          }
        }
        .MuiFormControl-root {
          border-bottom: 1px solid $grey2;
          padding: 0.5rem 1.6rem 0;
          height: 4.8rem;
        }
        .MuiButtonBase-root {
          font-size: 1.2rem;
        }
        .selected-tags {
          .MuiStack-root {
            .MuiChip-root {
              .MuiChip-deleteIcon {
                opacity: 0;
                font-size: 0.8rem;
                color: $black1;
                position: absolute;
                right: 0.4rem;
                top: 0.5rem;
                padding: 0.2rem;
                margin: 0;
                line-height: 1;
                display: inline-block;
                transition: all ease-in 0.1s;
                border-radius: 1rem;
                &::before {
                  font-weight: bold !important;
                  font-size: 0.8rem;
                }
                &:hover {
                  transform: scale(1.1);
                }
              }
              .MuiChip-label {
                display: block;
                span {
                  position: relative;
                  top: 0.2rem;
                }
              }
              &:hover {
                .MuiChip-label {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  display: block;
                  span {
                    top: 0.2rem;
                    left: -0.4rem;
                  }
                }
                .MuiChip-label {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: calc(100% - 0.8rem);
                }
                .MuiChip-deleteIcon {
                  opacity: 1;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .MuiStack-root {
          display: inline-flex;
          margin: 0 0.4rem 0.4rem 0;

          .MuiChip-root {
            background-color: #f3ebff;
            height: 2.4rem;
            display: inline-flex;
            align-items: center;
            border-radius: 1.2rem;
            max-width: 19rem;
            cursor: pointer;
            @include transition(all 0.1s ease-in);
            border: 1px solid transparent;
            .MuiChip-label {
              color: $black1;
              font-size: 1.2rem;
              font-weight: 500;
              @include line-height(12, 16);
              letter-spacing: 0.012rem;
              display: inline-flex;
              align-items: center;
              .be-tag-icon {
                &:before {
                  position: relative;
                  top: 0.3rem;
                  font-size: 1.25rem;
                  margin-right: 0.5rem;
                  font-weight: 600 !important;
                }
              }
            }
            .MuiChip-deleteIcon {
              padding: 0.3rem;
              border-radius: 100%;
              @include transition(all 0.1s ease-in);
              z-index: 2;
              cursor: pointer;
              &:hover {
                transform: scale(1.1);
              }
            }
            &:hover {
              border: 1px solid $purple2;
            }
          }
        }
        .tag-row {
          padding: 1.2rem 1.6rem;
          margin: 0;
          border-bottom: 1px solid $grey2;
          &:last-child {
            border: 0;
          }
          .MuiStack-root {
            margin-bottom: 0;
          }
          .custom-tag {
            padding: 0.4rem 0.8rem;
            height: 2.4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            font-size: 1.2rem;
            background: $lightPurple;
          }
          &:hover {
            background-color: $lightPurple1;
            cursor: pointer;
            .MuiChip-root {
              border-color: $purple2 !important;
            }
          }
        }
      }
    }
  }
}

.leads-by-bc-wrapper {
  display: flex;
  overflow: auto;
  @include custom-scroll;
  padding-bottom: 1.6rem;
  height: 23rem;
  &::-webkit-scrollbar-thumb {
    background: $grey12;
  }
  &::before,
  &::after {
    content: '';
    margin: auto;
  }
  .lead-col {
    width: 4.2rem;
    min-width: 4.2rem;
    margin-right: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-right: 0;
    }
    .lead-graph {
      margin-bottom: 0.8rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .count {
        font-size: 1.2rem;
        line-height: 1.6rem;
        display: block;
        margin-bottom: 0.8rem;
        color: $black;
      }
      .graph {
        height: 11.6rem;
        max-height: 11.6rem;
        background: $purple3;
        width: 1.6rem;
        margin: 0 auto;
        border-radius: 1rem 1rem 0 0;
        min-height: 1rem;
        &.is-empty {
          background-color: $grey2;
        }
      }
    }
    .lead-info {
      .dp {
        display: inline-block;
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;
        background: $lightPurple;
        overflow: hidden;
        border: 0.2rem solid $white;
        font-size: 1.2rem;
        line-height: 3rem;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .name {
        font-size: 1.2rem;
        line-height: 1.6rem;
        display: block;
        text-align: center;
        margin-top: 0.4rem;
        @extend .truncated-text;
      }
    }
  }
}
.btn-idea-board {
  &.MuiButtonBase-root {
    width: 3.6rem !important;
    min-height: 3.6rem;
    height: 3.6rem;
    margin-right: 1.6rem;
    background: $white !important;
    img {
      max-width: 17px;
    }
    &:hover {
      background: $grey1 !important;
    }
  }
}
.btn-start-working-idea {
  &.MuiButtonBase-root {
    font-size: 1.4rem;
    font-weight: 500;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
    color: $black1;
    min-width: 20.3rem !important;
  }
}

.wb-integration-wrapper {
  .page-filters-wrapper {
    padding: 1.5rem 3.2rem 1.8rem 1.8rem;
    justify-content: space-between;
    background: $grey7;
    position: relative;

    .left-page-header {
      display: flex;
      align-items: center;
      .MuiButtonBase-root {
        margin: 0 1.6rem 0 0;
        &.btn-back {
          background: $white;
          width: 3.6rem;
          height: 3.6rem;
          min-width: 3.6rem;
          border: 1px solid rgba(179, 136, 255, 0.3);
          .btn-icon {
            font-size: 1.6rem;
            margin: 0;
          }
        }
      }
      .dropdown-wrap {
        padding-left: 1.7rem;
        .MuiButtonBase-root {
          min-width: 10rem;
          width: 10rem;
          height: 3.6rem;
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 500;
          color: $black1;
          border-radius: 0.8rem;
          em {
            margin: 0 0 0 1.3rem;
            line-height: 1;
          }
        }
      }
    }
    .right-page-header {
      .compnay-logo {
        padding-right: 0;
        position: absolute;
        right: 0;
        top: 1.4rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 3.2rem;
        width: 10rem;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.faq-accordion {
  border: 0;
  .MuiAccordion-rounded {
    margin-bottom: 2rem !important;
    border: 0.1rem solid #ccc6d3 !important;
    border-radius: 0 0 0 2rem !important;
  }
  .MuiCollapse-root {
    border-top: 0.1rem solid $grey2;
    margin: 0 3rem 0;
  }
  .MuiAccordion-root {
    .MuiAccordionSummary-root {
      padding: 2.35rem 3rem;
      &::after {
        font-size: 1.5rem;
      }
    }
    .MuiAccordionDetails-root {
      padding: 1.2rem 0rem 2rem;
      font-size: 1.4rem;
      color: $black1;
    }
  }
}
.plans-pricing-wrapper {
  .plan-btn-wrap {
    .sm-btn {
      height: 6rem !important;
      background-color: $white;
      color: $black1;
      border: 1px solid rgba(179, 136, 255, 0.3);
      border-radius: 0.8rem;
    }
  }
}

.noactive-plans {
  background-color: $white;
  border: 0.1rem dashed #d9c4ff;
  padding: 5.5rem 3rem 6.5rem;
  margin-top: 2rem;
  text-align: center;
  border-radius: 0 0 0 2rem;
  @include bp(xs-max) {
    padding: 3rem;
  }
  .btn-block {
    justify-content: center;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .sm-btn {
      font-size: 14px;
      color: $black1;
    }
    .purple-btn {
      margin-left: 1.5rem;
      min-width: 20rem;
      @include bp(xs-max) {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }
  }
  p {
    margin: -0.5rem 0 2.2rem;
  }
  .imgframe {
    margin-bottom: 1.5rem;
  }
}

.pricing-login {
  .login-page {
    background: transparent;
    min-height: inherit;
    .login-wrapper {
      min-height: inherit;
      padding: 0rem 0 2rem 0;
      .logo {
        margin-bottom: 1rem;
      }
    }
  }
  p {
    font-size: 1.8rem;
    text-align: center;
  }
  .purple-btn {
    width: 95.5%;
    height: 6rem !important;
    margin: 2rem 1.5rem 0;
    @include bp(xs-max) {
      font-size: 12px;
      width: 90.5%;
    }
    img {
      width: 34px;
      border-radius: 100%;
      margin-right: 1rem;
    }
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 68rem;
      border-radius: 1.6rem;
      margin: 3.2rem 1.5rem;
      min-height: 620px;
      .no-btns {
        padding: 4rem 2.5rem 1.6rem;
        @include bp(sm-max) {
          padding: 4rem 1rem 1.6rem;
        }
      }
    }
  }
}

.speak-expert {
  .expert-content {
    min-height: 272px;
    .sent-img {
      margin-bottom: 1.5rem;
      display: block;
      @include bp(sm-max) {
        max-width: 14rem;
        margin: 0 auto 1.5rem;
      }
    }
    h3 {
      font-size: 2rem;
    }
  }
  .be-left-arrow {
    display: inline-flex;
    position: absolute;
    left: 3.2rem;
    top: 3.2rem;
    color: $black1;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .login-wrapper {
    padding: 0 !important;
  }
}

// Date Picker - custom
.sm-datepicker-popper {
  .MuiPaper-root {
    .MuiDateCalendar-root {
      width: 26.4rem !important;
      max-height: 28.4rem !important;
      //.MuiPickersCalendarHeader-root {}
      .MuiPickersFadeTransitionGroup-root {
        .MuiDayCalendar-root {
          .MuiDayCalendar-header {
            .MuiTypography-root {
              width: 3rem !important;
            }
          }
          .MuiDayCalendar-slideTransition {
            .MuiDayCalendar-monthContainer {
              .MuiDayCalendar-weekContainer {
                .MuiPickersDay-root {
                  width: 3rem !important;
                  height: 3rem !important;
                }
              }
            }
          }
        }
        .MuiYearCalendar-root {
          width: 26.4rem !important;
          .MuiPickersYear-root {
            width: 100% !important;
            max-height: 23rem !important;
            button {
              margin: 0.2rem 0 !important;
              height: 3rem !important;
              width: 6rem !important;
            }
          }
        }
      }
    }
  }
}

// Tag list : popper [Goals, playground]
.taglist-menu {
  &.MuiMenu-root .MuiMenu-paper.MuiPaper-rounded {
    box-shadow: 0px 4px 16px 0px #00000026 !important;
    min-width: 28.1rem;
    max-width: 28.1rem;
  }
}

.handle-timezone-modal-wrap {
  .handle-timezone-modal {
    padding: 1.6rem !important;
    background-color: $grey1 !important;
    .modal-close {
      right: 1.6rem !important;
      top: 1.6rem !important;
      font-size: 1.35rem !important;
    }
    .modal-title {
      margin-bottom: 1rem;
      max-width: calc(100% - 2rem);
    }
    p {
      font-size: 1.5rem;
    }
    .handle-timezone-footer {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      .button-container {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        button {
          margin-left: 1.6rem;
        }
      }
    }
  }
}
// Right side summary
.summary {
  position: relative;
  width: 33.6rem;
  height: auto;
  border-left: 1px solid $grey2;
  padding: 2.4rem;
  font-size: 1.4rem;
  color: $black1;
  background-color: $white;
  @include line-height(14, 20);
  overflow: hidden auto;
  @include custom-scroll;
  max-height: calc(100vh - 13.8rem);
  min-height: calc(100vh - 13.8rem) !important;
  @include bp(smd-max) {
    max-width: 100%;
    max-height: 100%;
    border: 0;
    border-bottom: 1px solid $grey2;
    border-left: 0;
    flex-wrap: wrap;
    display: flex;
  }
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.8rem 0;
    .MuiButtonBase-root {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      font-size: 1.8rem;
      font-weight: 400;
      color: $black1;
    }
    .MuiPaper-root {
      .MuiList-root {
        margin-top: 0.4rem;
      }
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 0.8rem 0;
  }

  .credits {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2.4rem;
    @include bp(smd-max) {
      width: 100%;
      padding: 0 0 1.6rem 0;
    }
    .credit-balance {
      .count {
        font-size: 3.2rem;
        @include line-height(32, 40);
        font-weight: 500;
        padding: 0 0 1.6rem;
        @include bp(smd-max) {
          padding: 0;
        }
      }
    }
    .request-credits {
      a {
        color: $purple1;
        text-decoration: underline;
        font-size: 1.2rem;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  .content-row {
    border-top: 1px solid $grey2;
    padding: 2.4rem 0;
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    @include bp(smd-max) {
      width: 100%;
      padding: 1.6rem 0;
    }

    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4285714286;
      letter-spacing: 0.01em;
      font-weight: 500;
      cursor: pointer;

      .initials,
      .user-avatar {
        @include user-avatar(
          1.2rem,
          500,
          $black1,
          $lightPurple,
          3.6rem,
          3.6rem,
          3.6rem
        );
        img {
          border-radius: 50%;
        }
      }
      .user-name {
        padding-left: 1.2rem;
      }
    }
    .user-detail {
      padding-left: 1.2rem;
      letter-spacing: 0.014rem;
      color: $black1;
      font-weight: normal;
      .user-name {
        padding-left: 0;
        color: $purple1;
      }
      .description-text {
        .time {
          color: $grey5;
        }
      }
    }
    .chips {
      .MuiStack-root {
        flex-direction: column;
        .MuiChip-root {
          margin: 0 0.8rem 0.8rem 0;
          max-width: fit-content;
          @include bp(lg-max) {
            max-width: 90%;
          }
        }
      }
    }
    .tags {
      .MuiStack-root {
        display: inline-block;
        .MuiChip-root {
          margin: 0 0.8rem 0.8rem 0;
          max-width: fit-content;
          @include bp(lg-max) {
            max-width: 90%;
          }
        }
      }
    }
    .recent-activity {
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      .user-link {
        padding: 0.8rem 0;
        font-size: 1.2rem;
        @include line-height(12, 20);
        align-items: flex-start;
      }
    }
  }
}

.members-spaces-sec {
  thead {
    position: sticky;
    top: 0;
    z-index: 9;
    background: $grey1;
  }
}

.summary,
.tabListView {
  // overflow: hidden auto;
  // @include custom-scroll;
  // max-height:calc(100vh - 26.8rem);
  // @include bp(xl-max) {
  //   max-height: calc(100vh - 28.5rem);
  // }

  &::-webkit-scrollbar-thumb {
    background: #f8f9fa;
    border-radius: 0.4rem;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: #afb2c0;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #afb2c0;
    }
  }
}

.selected-tags {
  .MuiButtonBase-root {
    position: relative;
    background: $lightPurple;
    border: 1px solid transparent;

    .MuiChip-deleteIcon {
      opacity: 0;
      font-size: 0.8rem !important;
      color: $black1 !important;
      position: absolute;
      right: 0.5rem;
      top: 0.3rem;
      padding: 0.4rem;
      margin: 0;
      line-height: 1;
      display: inline-block;
      background-color: $lightPurple;
      transition: all ease-in 0.1s;
    }
    &:hover {
      //.MuiChip-label {}
      background: $lightPurple;
      border: 1px solid $purple2;
      cursor: pointer;
      .MuiChip-deleteIcon {
        opacity: 1;
      }
    }
  }
}

.sidenav-visible {
  .checkout-steps-header {
    width: auto;
    left: 23rem;
  }
}

.ideaboard-tooltip,
.list-style-tooltip {
  ul {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 22rem;
    width: 20rem !important;
    @include custom-scroll;
    li {
      border-bottom: 0.1rem solid $grey6;
      font-size: 1.2rem;
      color: $black1;
      background: transparent !important;
      span {
        white-space: normal;
      }
      .initials {
        padding-top: 0.1rem;
      }
    }
    &.Mui-focusVisible {
      background: $white !important;
    }
  }

  .MuiMenu-paper {
    &.MuiPaper-rounded {
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15) !important;
      border-radius: 0.4rem !important;
      overflow: visible;
      margin-left: 9rem;
      margin-top: 1.3rem;
      &::before {
        position: absolute;
        top: -0.8rem;
        left: 50%;
        margin-left: -1rem;
        content: '';
        display: block;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid $white;
      }
    }
  }
}

// Three dot loader
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed $grey2;
  background: $lightPurple1;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  z-index: 9;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
// three dot loader : [dot flashing] end

.searching-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.no-meetings {
  padding: 6.5rem 1.5rem;
  h4 {
    font-size: 2rem !important;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1.6rem !important;
  }
  .purple-btn {
    width: 14rem;
    height: 4.8rem !important;
  }
}

.builder-logo-img {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.space-goals-wrapper {
  .tabListView {
    max-height: calc(100vh - 22.2rem);
    padding-bottom: 1.6rem;
    @include bp(lg-max) {
      max-height: calc(100vh - 28.3rem);
    }
    @include bp(smd-max) {
      //max-height: calc(100vh - 29.8rem);
      max-height: 100%;
    }
    @include bp(sm-max) {
      max-height: initial;
    }
  }
}

.highcharts-tooltip {
  pointer-events: auto !important;
}

.blur {
  // filter: blur(8px);
  opacity: 0.15;
}

.nrl-tag {
  text-transform: none !important;
}
// .idea-board-wrapper {
//   .secondary-header {
//     padding-bottom: 1.5rem;
//   }
// }
.bd-header {
  border-bottom: 1px solid $grey2;
  margin: 0 -2.4rem 1rem;
  padding: 0 2.4rem;
}

.cio-login {
  font-size: 1.4rem;
  color: $black1;
  font-weight: 400;
  .MuiSelect-select {
    color: $purple4;
    font-weight: 500;
    &::after {
      color: $black1;
      font-size: 1rem !important;
      font-weight: 600 !important;
      right: 5px !important;
    }
  }
}
.notistack-SnackbarContainer {
  left: 4rem !important;
}

.natasha-buildcard-banner {
  border: 0.1rem solid $purple;
  border-radius: 0.8rem;
  padding: 0.8rem 0.8rem 0.8rem 1.6rem;
  height: 5.2rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  background-color: $white;
  justify-content: space-between;
  @include bp(sm-max) {
    flex-wrap: wrap;
    height: auto;
  }
  .natasha-left-col {
    display: flex;
    align-items: center;
    p {
      color: $black;
      font-size: 1.4rem;
      padding-left: 2.2rem;
      line-height: 2.2rem;
      max-height: 4.8rem;
      margin-right: 0.2rem;
      overflow: hidden;
      overflow-y: auto;
      @include custom-scroll;
    }
  }
  .btn-group {
    display: flex;
    @include bp(sm-max) {
      margin-top: 0.8rem;
      margin-left: 3.5rem;
    }
    .btn-transparent {
      width: max-content;
    }
    .review-btn {
      background-color: $purple3;
      color: $white;
      margin-left: 0.8rem;
    }
    .MuiCircularProgress-root {
      width: 2.4rem !important;
      height: 2.4rem !important;
      margin-right: 0.8rem;
    }
  }
  .be-close {
    cursor: pointer;
    display: flex;
    color: $grey4;
    margin-right: 0.5rem;
    &:hover {
      cursor: pointer;
    }
    &:before {
      font-weight: 600 !important;
    }
  }
}

.review-tooltip {
  font-size: 1.4rem;
  line-height: 2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
  strong {
    display: block;
    margin-bottom: 0.3rem;
  }
  .got-btn {
    background-color: $grey13 !important;
    color: $black2 !important;
    width: 8.6rem;
    margin-top: 0.6rem;
  }
}
.bordered-dropdown-list {
  max-width: 32rem;
  li {
    padding: 0.8rem 1.6rem !important;
    min-height: 3.6rem !important;
    border-bottom: 1px solid $grey2;
    font-size: 1.4rem !important;
    display: flex !important;
    align-items: center;
    &:last-child {
      border-bottom: 0;
    }
    @include bp(lg-max) {
      font-size: 1.2rem !important;
    }
  }
}
.overflow-x-auto {
  overflow-y: hidden;
  @include custom-scroll;
}
.overflow-y-auto {
  overflow-x: hidden;
  @include custom-scroll;
}
.overflow-hidden {
  overflow: hidden !important;
}
.circular-bars {
  animation: fadeOut 2s ease-in-out forwards;
  position: absolute;
  top: 2.4rem;
  z-index: 2;
  left: 2.4rem;
  right: 2.4rem;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.graph-tooltip-wrap {
  background: $white;
  box-shadow: 0px 4px 16px 0px #00000026;
  padding: 0.8rem 1.6rem;
  border-radius: 0.3rem;
  .graph-tooltip-content {
    .date-text {
      color: $grey4;
      font-size: 1rem;
      line-height: 1.6rem;
    }
    .tickets-row {
      .title {
        color: $grey4;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
      .tickets-count {
        color: $black1;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;
        margin-left: 1rem;
        margin-right: 1rem;
      }
      .tickets-resolved {
        color: $purple1;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 500;
        .be-down-arrow-1 {
          transform: rotate(180deg);
          display: inline-flex;
          align-items: center;
          margin-right: 0.3rem;
          &::before {
            font-size: 1rem !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }
}

.highcharts-tooltip-container,
.pie-chart-wrapper,
.dashboard-container {
  .highcharts-tooltip {
    filter: none !important;
    fill: none !important;
    .highcharts-tooltip-box {
      fill: transparent !important;
    }
  }
}
.cs-chart-wrap {
  .highcharts-tooltip {
    fill: $white !important;
    .highcharts-tooltip-box {
      fill: $white !important;
    }
  }
}

// Chart tooltip
.chart-tooltip {
  box-shadow: 0 0.6rem 1.6rem 0 rgba(0, 0, 0, 0.15);
  margin: 0 0;
  border-radius: 0.4rem;
  background: $white;
  max-height: 24rem;
  overflow-x: hidden;
  overflow-y: auto;
  @include custom-scroll;

  .tooltip-arrow-left {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .head {
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    color: $dark-grey;
    border-bottom: 1px solid $grey2;
    word-break: break-all;
    text-transform: none;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .body {
    padding: 0.8rem 1.6rem;

    .content-row {
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
      .icon {
        height: 1.6rem;
        margin-right: 0.3rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        color: $grey11;
        font-size: 1rem;
        line-height: 1.6rem;
        margin-right: 0.4rem;
      }

      .count {
        color: $black1;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.6rem;
      }

      .tag-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 25rem;
        visibility: hidden;

        .custom-tag {
          visibility: visible;
          padding: 0.2rem 0.8rem;
          margin: 0 0.8rem 0.8rem 0;
          color: $black1;
          background: $lightPurple;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 1.6rem;
          border-radius: 1.2rem;
          max-width: 19.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-transform: none;
          letter-spacing: 0;
          &.colored {
            letter-spacing: 1px;
            font-size: 1rem;
            text-transform: uppercase;
          }

          &:last-child {
            margin: 0;
          }

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .credits-info-tooltip {
    min-width: 22rem;
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 0.8rem;
        span {
          display: block;
          color: $grey11;
          font-size: 1rem;
          margin-bottom: 0.4rem;
        }
        strong {
          font-size: 1.2rem;
          font-weight: 500;
          color: $black1;
          line-height: 1.2rem;
          display: flex;
          align-items: center;
        }
        .chip {
          font-size: 1.2rem;
          font-weight: 500;
          color: $black1;
          padding: 0.4rem 0.8rem;
          background-color: $lightPurple;
          height: 2.4rem;
          margin: 0 0.4rem 0.4rem 0;
          border-radius: 1.2rem;
          &.at_risk {
            background-color: $red1;
          }

          &.on_track {
            background-color: $green1;
            color: $white;
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 0.1rem;
            display: inline;
          }

          &.off_track {
            background-color: $amber;
          }
        }
        .chip-outer-wrap {
          display: flex;
          flex-wrap: wrap;
          strong {
            margin-right: 0.5rem;
          }
        }
      }
    }
    // &:after {
    //   border-top: 1rem solid transparent;
    //   border-bottom: 1rem solid transparent;
    //   border-right: 1rem solid $white;
    //   position: absolute;
    //   left: -0.9rem;
    //   top: 1rem;
    //   content: '';
    // }
  }
  .credits-info-header {
    border-bottom: 0.1rem solid $grey2;
    margin: 0 -1.6rem;
    padding: 0.5rem 1.5rem 0.8rem 1.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    color: $black1;
    margin-bottom: 0.8rem;
    img {
      margin-right: 0.5rem;
    }
  }
}

.highcharts-tooltip {
  pointer-events: auto !important;
}
#menu-country,
#menu-state_name {
  .MuiPaper-rounded {
    height: 200px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35) !important;
  }
}
.Mui-focusVisible {
  background-color: #fff !important;
}

// select dropdown svg
.select-icon {
  .MuiSvgIcon-root {
    display: none !important;
  }
  ::after {
    display: none !important;
  }
  svg {
    position: absolute;
    right: 1.2rem;
    top: calc(50% - 1.7rem);
    height: 3.6rem;
    width: 3.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }
  &.borderless-dropdown {
    svg {
      display: inline-flex !important;
      right: -8px;
      top: calc(50% - 1.2rem);
      height: 2.4rem;
      width: 2.4rem;
    }
    &.select-status {
      svg {
        top: calc(50% - 1.8rem);
        height: 3.5rem;
        width: 3.6rem;
        @extend .transition;
      }
    }
    &.select-days,
    &.select-analysis {
      svg {
        top: calc(50% - 1.1rem) !important;
        height: 2.4rem;
        width: 2.4rem;
        @extend .transition;
      }
    }
    &.select-analysis {
      svg {
        right: 0.8rem;
      }
    }
    &.sm-right {
      svg {
        right: 0;
        @extend .transition;
      }
    }
  }
  &.select-analysis {
    svg {
      top: calc(50% - 1.4rem);
      height: 2.8rem;
      width: 2.8rem;
      right: 0.4rem;
    }
  }
}

.tywriter-text {
  .blinker {
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-duration: 1s;
    display: inline-flex;
    width: 0.5rem;
    height: 1.4rem;
    margin-left: 0.2rem;
    border-radius: 2px;
    box-shadow: 0 0 0.4rem rgba(white, 0.3);
    background: linear-gradient(283.64deg, #c399ff 0%, #8833ff 103.54%);
    border: 1px solid $grey25;
    position: relative;
    top: 0.2rem;
    left: auto;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.blue {
  background-color: #3d5afe !important;
}
.browse-link {
  font-size: 1.4rem;
  color: $purple1;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  outline: none;
}
