@import './assets/scss/main';

::-moz-selection {
  color: $white;
  background: $purple;
}

::selection {
  color: $white;
  background: $purple;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
  min-height: 100vh;
}

body {
  font-size: 1.6rem;
  @include line-height(16, 24);
  min-width: 32rem;
  overflow-x: hidden;
  background: $grey1;
  color: $black1;
  min-height: 100vh;
  font-family: $primaryFont;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  margin: 0;
  letter-spacing: 0;
}

* {
  font-family: $primaryFont;
  letter-spacing: 0;
}

*:focus {
  outline: none !important;
}

a {
  display: inline-block;
  @extend .transition;
  text-decoration: none;
  color: $purple1;
  cursor: pointer;
  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.primary-link {
  text-decoration: underline;
  color: $purple1;
  font-size: 1.6rem;
  @include line-height(16, 24);
  @extend .transition;
  cursor: pointer;
  &:hover {
    color: $purple1;
    text-decoration: none;
  }
  &.is-red {
    color: $red;
  }
  &.secondary {
    color: $black1;
  }
}

.container {
  max-width: 117.6rem;
  margin: 0 auto;
  &.xs {
    max-width: 60rem;
  }
  @include bp(sm-max) {
    padding: 0 2rem;
  }
}

img {
  display: inline-block;
  max-width: 100%;
}

::-ms-clear {
  display: none;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.wrapper {
  min-height: 100vh;
}

.main-content {
  min-height: 100vh;
  padding-left: 5.6rem;
  transition: all 0.3s;

  @include bp(md-max) {
    padding-left: 0;
    .sidenav-visible & {
      padding-left: 0;
    }
  }
  .sidenav-submenu-fixed & {
    @include bp(lg-min) {
      padding-left: 26.5rem;
    }
  }
}

.page-container {
  padding: 0 2.4rem;
  @include bp(lg-max) {
    padding: 0 1.8rem;
  }
  @include bp(sm-max) {
    padding: 0 1.6rem !important;
    overflow-x: hidden;
  }
  &.top-space {
    padding-top: 1.5rem !important;
    padding-bottom: 2.4rem;
    &.xs-space {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 0.1rem solid $grey2;
    }
  }
  &.sm-x-pad {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.text-grey {
  color: $trueGrey !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.ticked-list {
  @extend .no-bullets;
  li {
    position: relative;
    padding-left: 2.9rem;
    font-size: 1.4rem;
    @include line-height(14, 24);
    color: $black1;
    margin-bottom: 0.8rem;
    &:last-child {
      margin: 0;
    }
    &::before {
      content: '\69';
      position: absolute;
      left: 0.6rem;
      top: 0.6rem;
      font-family: $enterpriseFont;
      color: $purple1;
      font-size: 1.5rem;
      line-height: 1;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  @include custom-scroll;
}

.text-danger {
  color: $red !important;
}

strong {
  font-weight: 500;
}

.secondary-loader {
  display: inline-flex;
  .spinner {
    animation: rotate 2s linear infinite;
    .animated-path {
      stroke: $purple1;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

.file-uploading {
  border-color: #b388ff !important;
  background: #f3ebff !important;
}

.bg-transparent {
  background: transparent !important;
}

.htmlContent {
  strong {
    padding-left: 0;
    color: $purple1;
    cursor: pointer;
  }
}

.hide-scrollbar {
  overflow: hidden !important;
}

.studio-iframe,
.wb-integration-iframe {
  background-color: $white;
  height: 100vh;
  left: 5.5rem;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100vw - 5.5rem);
  border: 0;
  .sidenav-submenu-fixed & {
    @include bp(lg-min) {
      left: 26.4rem;
      width: calc(100% - 26.4rem);
    }
  }
  @include bp(md-max) {
    left: 0;
    width: 100vw;
    // .sidenav-visible & {
    //   left: 5.5rem;
    //   width: calc(100vw - 5.5rem);
    // }
  }
}

.sidenav-visible {
  .studio-iframe,
  .wb-integration-iframe {
    left: 24rem;
    width: calc(100vw - 24rem);
  }
}

.wb-integration-iframe {
  height: calc(100vh - 5.6rem);
  //bottom: 0;
  top: 5.6rem;
  @include bp(md-max) {
    top: 9rem;
  }
  @include bp(sm-max) {
    top: 12rem;
  }
}

.cr-pointer {
  cursor: pointer;
}

.label-box {
  width: 100%;
  // height: 50px;
  font-size: 15px;
  padding: 0 10px 0 10px;
  background-color: #f0f0f0;
  display: inline-block;
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 1px;
  color: #3c3e49;
  em {
    width: 10px;
    display: inline-block;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
}

.studio-iframe-header {
  border-top: 1px solid $grey2;
  margin-top: 5.6rem;
  height: calc(100vh - 5.61rem);
}
