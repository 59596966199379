@import '../scss/mixins';
@import '../../components/dialog/bmeet-member-dialog/bmeet.scss';
.feature-detail-dialog-wrapper {
  .MuiPaper-root {
    width: 78rem !important;
    height: 47.6rem !important;
  }
  .MuiDialogContent-root {
    &.md-padding {
      padding: 4rem 4rem 3.2rem !important;
    }
  }
}
.MuiDialog-root {
  .MuiBackdrop-root {
    &[class$='MuiDialog-backdrop'],
    &.MuiModal-backdrop {
      background: rgba($grey7, 0.8);
    }
  }
  &.sm-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        width: 52.8rem;
      }
    }
  }
  &.lg-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        width: 102.2rem;
      }
    }
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 63.6rem;
      max-width: 100%;
      box-shadow: 0 0.4rem 1.6rem rgba($black, 0.15);
      border-radius: 0.8rem;
      margin: 3.2rem 1.5rem;
      .MuiDialogContent-root {
        position: relative;
        //padding: 6rem 6rem 1.6rem;
        padding: 3.2rem 4rem 4rem;
        @include custom-scroll;
        color: $black1;
        @include bp(sm-max) {
          padding: 3.5rem 2rem 1.6rem;
        }
        &.hori-sm-padding {
          padding-left: 4.7rem;
          padding-right: 4.7rem;
          @include bp(lg-max) {
            padding-left: 4rem;
            padding-right: 4rem;
          }
          @include bp(sm-max) {
            padding-left: 2rem;
            padding-right: 2rem;
          }
          .modal-btn-block {
            margin-left: -3.1rem;
            margin-right: -3.1rem;
            @include bp(lg-max) {
              margin-left: -1.5rem;
              margin-right: -1.5rem;
            }
            @include bp(sm-max) {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
        &.md-padding {
          padding-left: 3.2rem;
          padding-right: 3.2rem;
          padding-bottom: 3.2rem;
        }
        &.smd-padding {
          padding: 4rem;
        }
        &.normal-hori-padding {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
          .modal-btn-block {
            margin-left: 0;
            margin-right: 0;
          }
        }
        &.no-btns {
          padding-bottom: 4.2rem;
          @include bp(sm-max) {
            padding-bottom: 3.5rem;
          }
        }
        .modal-close {
          display: inline-flex;
          position: absolute;
          right: 3.2rem;
          top: 3.2rem;
          color: $black1;
          font-size: 1.8rem;
          cursor: pointer;
          @include bp(sm-max) {
            right: 2rem;
            top: 2rem;
          }
        }
        .sm-text {
          font-size: 1.4rem;
          @include line-height(14, 20);
          color: $trueGrey;
        }
      }
      &.buildcard-status-dialog {
        width: 100%;
        height: 100%;
        margin: 5rem;
        .MuiDialogContent-root {
          padding: 2.5rem 4.6rem 4rem;
        }
      }
    }
  }
}

.modal-pre-title-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #3c3e49;
  margin-bottom: 3.2rem;
  .icon {
    font-size: 3rem;
    width: 3rem;
    font-weight: 400;
    line-height: 0;
  }
  .content {
    width: calc(100% - 3rem);
    padding-left: 1.1rem;
    strong {
      font-weight: 500;
    }
  }
}

.modal-title {
  margin-bottom: 3.2rem;
  h2 {
    word-wrap: break-word;
    font-size: 2rem;
    line-height: 3.2rem;
  }
  &.has-right-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    h2 {
      margin: 0;
    }
    .right-title {
      @include bp(sm-max) {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
  h2 {
    margin-bottom: 0.4rem;
    letter-spacing: 0.01em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    &.note {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.01rem;
      color: $grey11;
    }
  }
}

.modal-btn-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3.6rem -4.4rem 0;
  @include bp(sm-max) {
    margin: 3.5rem 0 0;
  }
  @include bp(xs-max) {
    flex-direction: column;
  }
  .MuiButton-root {
    width: calc(50% - 0.8rem);
    @include bp(xs-max) {
      width: 100%;
      margin-bottom: 0.8rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.full-width {
    margin: 3.6rem -2.4rem -1.6rem;
    padding: 1.6rem;
    box-shadow: 0.4rem 0.4rem 2.4rem 0rem rgba(41, 0, 88, 0.15);
    &.lg-margin {
      margin-left: -6rem;
      margin-right: -6rem;
      @include bp(sm-max) {
        margin-left: -2rem;
        margin-right: -2rem;
      }
    }
  }
}

.modal-form {
  .member-permission-wrapper {
    margin-top: 2.1rem;
    .users-list {
      min-height: 10rem;
      padding-left: 1.2rem;
    }
  }
}

.purchase-order-sent-content {
  .icon {
    display: inline-flex;
    margin-bottom: 1.8rem;
  }
  .modal-title {
    .email {
      display: block;
      word-break: break-all;
    }
  }
}

.connect-with-expert {
  .dp {
    display: inline-flex;
    height: 6.4rem;
    width: 6.4rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0.8rem;
    img {
      height: 100%;
      width: 100%;
      @extend .cover-fit;
    }
  }
  .expert-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $black1;
    .expert-content {
      padding-top: 0.6rem;
      width: calc(100% - 20rem);
      @include bp(xs-max) {
        width: 100%;
        padding-top: 0;
      }
      h6 {
        margin-bottom: 0.8rem;
      }
      .contact-details {
        @extend .no-bullets;
        li {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.8rem;
          &:last-child {
            margin: 0;
          }
          .title {
            width: 5.7rem;
            position: relative;
            &:after {
              content: ':';
              position: absolute;
              right: 0;
              line-height: 0;
              top: 50%;
              @include translateY(-50%);
            }
          }
          .content {
            width: calc(100% - 5.7rem);
            padding-left: 0.4rem;
          }
        }
      }
      .or-block {
        max-width: 20rem;
      }
      .MuiButton-root {
        width: 20rem;
        max-width: 100%;
      }
      .request-received {
        display: flex;
        flex-wrap: wrap;
        width: 23.2rem;
        max-width: 100%;
        .icon {
          display: inline-flex;
          width: 3.8rem;
        }
        .content {
          width: calc(100% - 3.8rem);
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: 0.01em;
          padding-left: 0.9rem;
        }
      }
    }
    .builder-logo {
      font-size: 17rem;
      color: $lightPurple;
      width: 20rem;
      text-align: right;
      @include bp(xs-max) {
        display: none;
      }
      em[class^='be-'] {
        display: block;
        line-height: 0;
      }
    }
  }
}

.member-list-wrapper {
  font-size: 14px;
  @include line-height(14, 20);
  h6 {
    @include line-height(14, 24);
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  .no-member-selected {
    letter-spacing: 0.01em;
    color: $trueGrey;
  }
  .member-list-outer {
    &:empty {
      height: 3.8rem;
      @include bp(smd-max) {
        height: 2rem;
      }
    }
    .member-list {
      height: max-content;
      max-height: 24rem;
      overflow: auto;
      @include custom-scroll;
      @extend .no-bullets;
      padding-right: 1.6rem;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.8rem;
        @extend .last-row-tooltips;
        &:last-child {
          margin-bottom: 0;
        }
        .tooltip-wrapper {
          .tooltip-trigger {
            display: flex;
            align-items: center;
            .tooltip-icon {
              color: $amber;
            }
          }
        }
        .email {
          width: calc(100% - 1.5rem);
          padding-right: 1.5rem;
        }
        .close-icon {
          cursor: pointer;
          font-size: 1.5rem;
          line-height: 0;
          color: $grey11;
          @extend .transition;
          &:hover {
            color: $purple1;
          }
        }
      }
    }
  }
}

.bulk-member-upload-wrapper {
  min-height: 46rem;
  @include bp(smd-max) {
    min-height: 42rem;
  }
  @include bp(xs-max) {
    min-height: inherit;
  }
  .table-wrapper {
    margin-top: 1.6rem;
    .tooltip-wrapper {
      .tooltip-trigger {
        display: flex;
        align-items: center;
        .tooltip-icon {
          color: $amber;
        }
      }
    }
    .MuiTableRow-root {
      @extend .last-row-tooltips;
    }
  }
  .member-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.6rem;

    .imported-numbers {
      font-size: 1.4rem;
      @include line-height(14, 24);
      font-weight: 500;
      @include bp(sm-max) {
        width: 100%;
      }
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include bp(sm-max) {
        margin-top: 1.5rem;
      }
      @include bp(xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
      .browse-files-link {
        margin-right: 3.2rem;
        @include bp(sm-max) {
          margin-right: 2rem;
        }
        @include bp(xs-max) {
          margin-right: 0;
          margin-bottom: 1.5rem;
        }
        &:last-child {
          margin: 0;
        }
        .icon {
          font-size: 1.5rem;
        }
      }
    }
    .browse-files-link {
      position: relative;
      z-index: 1;
      &:hover {
        .email-box {
          opacity: 1;
          visibility: visible;
          @include translateY(0);
        }
      }
      .email-box {
        position: absolute;
        right: 9.5rem;
        top: calc(100% + 1.6rem);
        width: 75rem;
        max-width: 80vw;
        background-color: $white;
        box-shadow: 0.4rem 0.4rem 4rem rgba(41, 0, 88, 0.15);
        border-radius: 1.6rem;
        padding: 3.2rem;
        z-index: 1;
        @extend .transition;
        opacity: 0;
        visibility: hidden;
        @include translateY(1rem);
        @include bp(lg-max) {
          padding: 2rem;
          right: 0;
        }
        @include bp(sm-max) {
          left: 0;
          right: auto;
          padding: 1.2rem;
          margin-left: -10rem;
        }
        @include bp(xs-max) {
          margin-left: 0;
        }
        .email-thumb {
          display: block;
        }
      }
    }
  }
  .system-msg-wrapper {
    margin-top: 2.4rem;
  }
  .photo-upload-box {
    margin-top: 2.4rem;
  }
}

.switch-view-wrap {
  margin-bottom: 1.6rem;
  .button-grouping {
    display: inline-flex;
  }
}

.feature-detail-dialog {
  display: flex;
  flex-wrap: wrap;
  .feature-thumb-col {
    width: 16rem;
    @include bp(xs-max) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 25rem;
        @include transition(all ease-in 0.2s);
      }
    }
  }
  .feature-info-col {
    width: calc(100% - 16rem);
    padding-left: 3.2rem;
    //transition: all ease-in-out 0.2s;
    @include bp(xs-max) {
      width: 100%;
      padding-left: 0;
      padding-top: 3rem;
    }
    .title-block {
      margin-bottom: 0.8rem;
      h4 {
        margin-bottom: 0;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $grey11;
      }
    }
    .credit-duration {
      @extend .no-bullets;
      margin-bottom: 0.8rem;
      li {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 0.4rem;
        &:last-child {
          margin: 0;
        }
        .title {
          color: $grey5;
          margin-right: 0.5rem;
        }
      }
    }
    .feature-desc {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.014rem;
      color: $trueGrey;
      max-height: 15.5rem;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      @include custom-scroll;
    }
  }
}
.mobile-view {
  // .switch-view-wrap {
  //   padding-left: 3.2rem;
  // }
  .feature-detail-dialog {
    // padding-left: 3.2rem;
    // padding-right: 3.2rem;
    .feature-thumb-col {
      transition: all ease 0.1s;
      img {
        border-radius: 1.4rem;
        border: 1px solid $grey18;
      }
    }
    .feature-info-col {
      padding-top: 3.2rem;
      //.feature-desc {}
    }
  }
}
.desktop-view {
  .feature-detail-dialog {
    align-items: flex-start;
    .feature-thumb-col {
      transition: all ease 0.1s;
      width: 39.2rem;
      img {
        width: 100%;
        border-radius: 1.4rem;
        border: 1px solid $grey18;
      }
    }
    .feature-info-col {
      width: calc(100% - 39.2rem);
      padding-top: 0;
      // .feature-desc { }
    }
  }
}

// Credit history modal
.credit-history-modal {
  &.MuiDialog-root {
    .MuiDialog-container {
      .MuiDialog-paper {
        width: 92.9rem;
      }
    }
  }
}

.remove-journey-modal {
  .modal-title {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.attachment-dialog {
  .MuiDialog-paper {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    max-height: 100%;
    .MuiDialogContent-root {
      padding: 3.2rem !important;
      .modal-close {
        width: 2.4rem;
        height: 2.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &::before {
          font-size: 1.25rem;
          font-weight: bold !important;
        }
      }
      .modal-body {
        .modal-title {
          margin: 0 0 2.4rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .right {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            .modal-close {
              position: relative;
              top: auto;
              right: auto;
            }
          }
        }
      }
    }
    .hysiap {
      overflow: visible;
    }
  }
  iframe,
  .attachment-container {
    height: calc(100vh - 13.2rem);
    border: 1px solid $lightPurple;
    background: $lightPurple1;
  }
}

.edit-tax-modal {
  .custom-label {
    font-size: 1.6rem;
    font-weight: 500;
    color: $black1 !important;
    margin-bottom: 0.8rem;
    .be-info {
      font-size: 1.7rem;
      position: relative;
      top: 0.3rem;
      margin-left: 0.3rem;
      &:hover {
        cursor: pointer;
      }
      &::before {
        font-weight: 400 !important;
      }
    }
  }
  .custom-radio-btn {
    margin-left: 0.3rem;
    .MuiRadio-root {
      .css-1aj8orz-MuiSvgIcon-root {
        transform: scale(1.5) !important;
        color: $grey;
      }
      &.Mui-checked {
        .css-1aj8orz-MuiSvgIcon-root {
          color: $purple1;
        }
      }
    }
    .MuiFormControlLabel-label {
      margin-left: 0.5rem;
    }
  }
}

.edit-billing-dialog,
.edit-contact-dialog,
.edit-tax-modal {
  .phone-no-group {
    .country-code-field {
      .react-tel-input {
        &::after {
          content: 'N';
          font-family: 'builder-enterprise';
          font-size: 1.4rem;
          transform: translateY(-87%);
          transition: all ease-in 0.3s;
        }
        input {
          caret-color: transparent;
        }
        .country-list {
          top: calc(100% + -0.2rem);
          bottom: -17rem;
          max-height: 15.4rem;
          transform: none;
          transition:
            opacity 281ms cubic-bezier(0.4, 0, 0.2, 1),
            transform 187ms cubic-bezier(0.4, 0, 0.2, 1);
          @include bp(sm-max) {
            bottom: auto;
          }
          .country {
            line-height: 2rem;
            margin-bottom: 0rem;
          }
        }
        &:has(.flag-dropdown.open)::after {
          transform: rotate(180deg);
          margin-top: -20px;
        }
      }

      &.flag-dropdown2 {
        .country-list {
          max-height: 19.4rem !important;
          bottom: auto !important;
          top: calc(100% - 0.2rem) !important;
          .country {
            line-height: 2.2rem !important;
            margin-bottom: 0.5rem !important;
          }
        }
      }
    }
  }
}

.edit-template-dialog {
  .file-uploading {
    border: 1px dashed #ded6f1 !important;
    background-color: transparent !important;
    text-align: center;
    height: 56px;
    padding: 10px 15px;
    border-radius: 0.8rem;
    width: 85%;
    p {
      font-size: 1rem;
      color: $grey4;
      font-weight: 400;
      position: relative;
      top: -1px;
    }
    span {
      font-size: 1.4rem;
      color: $trueGrey;
      font-weight: 400;
    }
    .browse-files-link {
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 0.3rem;
      position: relative;
      top: 0.1rem;
    }
    .thumb {
      margin-right: 0.5rem;
      display: flex;
    }
  }
  .textarea-box {
    position: relative;
    .num {
      color: $grey4;
      font-size: 1rem;
      font-weight: 400;
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      z-index: 1;
    }
  }
  .or {
    color: $grey;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 0.8rem;
  }
  label {
    font-size: 1.4rem;
    color: $black1;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .custom-textarea {
    textarea {
      margin-top: 1.8rem;
    }
  }
  .be-refresh {
    margin-right: 0.7rem;
    position: relative;
    top: 2px;
  }
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.edit-buildcard-dialog {
  .modal-title {
    margin-bottom: 1.2rem;
    h2 {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }
  input {
    padding-top: 1rem;
    font-size: 1.6rem;
    color: $black1;
    font-weight: 400;
  }
  label {
    font-size: 1.6rem;
    color: $black1;
    font-weight: 400;
    margin-bottom: 0.8rem;
  }
  .form-group {
  }
}
// Fixed footer : Modal
.fixed-footer-wrap {
  padding: 0 !important;
  box-shadow: 0 -0.2rem 0.8rem 0 rgba(0, 0, 0, 0.08);
  .fixed-full-footer {
    display: flex;
    padding: 1.5rem 4rem;
    width: 100%;
    @include bp(sm-max) {
      padding: 1.5rem;
    }
    .sm-btn {
      width: 50%;
      @include bp(sm-max) {
        width: 65%;
        font-size: 1.4rem;
      }
    }
    .grey-btn {
      width: calc(50% - 1.6rem);
      margin-right: 1.6rem;
      @include bp(sm-max) {
        width: calc(35% - 1.6rem);
      }
    }

    &.sign-in {
      flex-flow: column;
      .sm-btn {
        width: 100%;
        margin: 0;
        height: 4.8rem !important;
      }
      p {
        margin: 1.6rem 0;
      }
    }
    &.studio-footer {
      .grey-btn {
        width: calc(65% - 1.6rem);
      }
    }
  }
}

.archive-buildcard-modal {
  .MuiDialog-container .MuiDialog-paper {
    width: 58.2rem;
  }
}

.approve-buildcard-modal {
  .title-wrap {
    h4 {
      margin-bottom: 1.6rem;
    }
    p {
      margin-bottom: 2.8rem;
    }
  }
  textarea {
    resize: none;
  }
  ::placeholder {
    color: $trueGrey;
  }
}

.add-feedback-reject-dialog {
  margin: 1rem 0 0;
  textarea {
    width: 100%;
    resize: none;
  }
  .form-group {
    margin-bottom: 0;
  }
}

.discard-modal,
.customiz-dialog {
  .MuiDialog-paper {
    width: 57.4rem !important;
    max-width: 57.4rem !important ;
    h2 {
      margin-bottom: 1rem;
    }
  }
}

.approve-dialog {
  .warning-note {
    background: $orange2;
    padding: 1.1rem 1.2rem;
    border-left: 0.4rem solid $amber;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    color: $black1;
    margin: 2rem 0 2rem;
    letter-spacing: 0.01em;
  }
  .system-msg {
    padding: 1.1rem 1.2rem;
    margin-top: 1rem;
    p {
      font-size: 1.2rem;
      @include line-height(12, 16);
      color: $red;
    }
  }
}
