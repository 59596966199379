.MuiTableContainer-root {
  @include custom-scroll;
}

.MuiTable-root {
  font-size: 1.4rem;
  line-height: 2rem;
  &.auto-width {
    min-width: inherit;
    width: auto;
  }
  &.has-padding {
    .MuiTableCell-head {
      padding-left: 2.4rem;
      &:last-child {
        padding-right: 2.4rem;
      }
    }
    .MuiTableCell-body {
      padding-left: 2.4rem;
      &:last-child {
        padding-right: 2.4rem;
      }
    }
  }
  .MuiTableRow-root {
    &:last-child {
      td {
        border-bottom: 0.1rem solid $grey2;
      }
    }
  }
  .MuiTableCell-head {
    height: 4rem;
    font-size: 1rem;
    @include line-height(10, 16);
    letter-spacing: 0.1rem;
    color: $black1;
    padding: 0 2.4rem 0 0;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 0.1rem solid $grey2;
    font-family: $primaryFont;
    vertical-align: middle;
    &:last-child {
      padding-right: 0;
    }
    .tooltip-wrapper {
      .tooltip-trigger {
        .tooltip-icon {
          font-size: 1.2rem;
          color: $grey11;
          top: -0.1rem;
        }
      }
    }
  }
  .MuiTableCell-body {
    padding: 0 2.4rem 0 0;
    border-bottom: 0.1rem solid $grey2;
    font-size: 1.4rem;
    @include line-height(14, 20);
    letter-spacing: 0.01em;
    color: $black1;
    font-family: $primaryFont;
    overflow: inherit;
    height: 4.8rem;
    vertical-align: middle;
    &:last-child {
      padding-right: 0;
    }

    .MuiInputBase-formControl {
      margin-right: 0.3rem;
      &.borderless-dropdown {
        .MuiSelect-select {
          padding-right: 2rem !important;
        }
      }
      .MuiSelect-select {
        &::after {
          right: 0;
        }
      }
    }

    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      @include line-height(14, 20);
      letter-spacing: 0.01em;
      font-weight: 500;
      color: $purple1;
      cursor: pointer;
      &.is-grey {
        color: $trueGrey;
        cursor: default;
        .initials {
          background: $grey6;
          color: $grey11;
          font-size: 1.8rem;
        }
      }
      .initials {
        height: 3.2rem;
        width: 3.2rem;
        min-width: 3.2rem;
        border-radius: 50%;
        background: $lightPurple;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: $black1;
        font-weight: 400;
        text-transform: uppercase;
      }
      .user-name {
        padding-left: 1.2rem;
        max-width: 25rem;
        display: flex;
        line-height: 1.8rem;
        @extend .truncated-text;
      }
    }

    .email-invitation-dropdown {
      display: flex;
      align-items: center;
      .MuiButton-root {
        margin-left: 1rem;
        display: inline-flex;
        min-width: 10rem;
        em {
          &[class^='be-'] {
            line-height: 0;
            font-size: 1rem;
            margin-left: 0.8rem;
          }
          &.be-tooltip {
            font-size: 1.4rem;
            margin-left: 0;
            margin-right: 0.5rem;
            color: $red;
          }
        }
      }
    }
  }
}

.be-left-arrow {
  display: inline-flex;
  &.move-down {
    transform: rotate(270deg);
  }
  &.move-up {
    transform: rotate(90deg);
  }
}
