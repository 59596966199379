@import '../../../assets/scss/colors';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/extended-classes';
@import '../../../assets/scss/break-points';
@import '../../../assets/scss/search-dropdown';

.chips-wrapper {
  margin: 0.8rem 0;
  .MuiStack-root {
    flex-flow: row wrap;
    .MuiButtonBase-root {
      @extend .transition;
      margin: 0.8rem 0.4rem;
      position: relative;
      background: $lightPurple;
      padding: 0.4rem 0.8rem;
      justify-content: flex-start;
      border: 1px solid transparent;
      height: 2.4rem;
      .MuiChip-label {
        transition: all 0.2s ease-in;
        padding: 0.2rem 0.8rem;
        color: $black1;
        font-size: 1.2rem;
        font-weight: 500;
        @include line-height(12, 16);
        min-width: 2.8rem;
        //max-width: 16rem;
      }
      .MuiChip-deleteIcon {
        opacity: 0;
        font-size: 0.8rem;
        color: $black1;
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
        padding: 0;
        margin: 0;
        line-height: 1;
        transition: all ease-in 0.2s;
        &::before {
          font-weight: bold !important;
          font-size: 0.8rem;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      .icon {
        font-size: 1rem;
        color: $black1;
        margin: 4px 0 0;
      }
      &:hover {
        border: 1px solid $purple2;
        .MuiChip-label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(96%);
        }
        .MuiChip-deleteIcon {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.css-w4z10b-MuiStack-root {
  flex-wrap: wrap;
}
.select-popover {
  .MuiPaper-root & {
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;
    @include custom-scroll;
  }
}
.tab-content-wrapper {
  .MuiTabs-root {
    min-height: 4rem;
    .MuiTabs-fixed {
      .MuiTabs-flexContainer {
        .MuiTab-root {
          margin-right: 4.8rem;
          min-height: 4rem;
          padding: 0.8rem 0;
          color: $black1;
          font-size: 1.6rem;
          font-weight: 400;
          @include line-height(16, 24);
          &.Mui-selected {
            color: $purple1 !important;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.tab-panel-wrapper {
  padding: 3.2rem 0 0 !important;
  .be-close-dark {
    margin: -1px 0 0 !important;
  }
}
.tab-content-section {
  .select-box {
    .MuiSelect-select {
      padding-right: 1.4rem;
      z-index: 10;
    }
    .MuiSvgIcon-root {
      display: none;
    }
    &.custom-autocomplete {
      &.Mui-focused {
        fieldset {
          legend {
            span {
              background-color: transparent;
              display: none !important;
            }
          }
        }
      }
    }
    &.select-video-conferencing {
      > .MuiSelect-select {
        display: flex;
        align-items: center;
      }
      .icon-wrap {
        margin-right: 2.8rem;
        line-height: 1;
        img {
          max-height: 2.4rem;
        }
      }
    }
  }

  .date-picker-wrap {
    .MuiStack-root {
      padding: 0;
      .basic-date-picker {
        min-width: 15rem;
        .Mui-focused {
        }
      }
    }
    background: $white;
    label {
      background: none;
      top: -1px;
      &.Mui-focused {
        top: -5px;
      }
    }
    textarea {
      padding-top: 1rem;
    }
    .MuiFormControl-root {
      .MuiFormLabel-root {
        &.MuiFormLabel-filled {
          display: none;
        }
      }
    }
    .input-reset {
      .MuiInputBase-root {
        input {
          padding: 1.7rem 0 1.6rem 1.8rem !important;
        }
      }
    }
    .MuiInputBase-root {
      fieldset {
        legend {
          span {
            background-color: transparent;
            display: none;
          }
        }
      }
      input {
        padding: 1.7rem 0 1.6rem 1.8rem !important;
      }
      .MuiButtonBase-root {
        margin-right: -8px;
        width: 2.4rem;
        height: 2.4rem;
        .MuiSvgIcon-root {
          color: $black1;
          font-size: 2rem;
        }
      }
      &.Mui-focused {
      }
    }
  }
}

// Select Video conferencing menu items
.select-video-menu-item {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem !important;
  .icon-wrap {
    width: 5rem;
    display: flex;
    align-items: center;
    img {
      height: 2rem;
    }
  }
}

.MuiPaper-root {
  // Calendar
  .MuiPickersLayout-root {
    .MuiPickersLayout-contentWrapper {
      .MuiDateCalendar-root {
        width: 34.4rem;
        max-height: 34.4rem;
        .MuiPickersCalendarHeader-root {
          position: relative;
          .MuiPickersCalendarHeader-switchViewButton {
            display: none;
          }
          .MuiPickersCalendarHeader-labelContainer {
            padding-left: 0;
            margin: -0.4rem auto 0;
          }
          .MuiPickersCalendarHeader-label {
            color: $black1;
            font-size: 1.4rem;
            font-weight: 500;
            @include line-height(14, 20);
            letter-spacing: 0.014rem;
          }

          .MuiPickersArrowSwitcher-root {
            // .MuiButtonBase-root {
            //   &.Mui-disabled {
            //     .MuiSvgIcon-root {}
            //   }
            // }
            .MuiPickersArrowSwitcher-spacer {
              display: none;
            }
            .MuiButtonBase-root {
              height: 2.4rem;
              width: 2.4rem;
              position: absolute;
              top: 0;
              &:first-child {
                left: 2.4rem;
              }
              &:last-child {
                right: 2.4rem;
              }
              .MuiSvgIcon-root {
                color: $grey11;
              }
            }
          }
        }
        .MuiDateCalendar-viewTransitionContainer {
          font-size: 1.4rem;
          font-weight: 400;
          @include line-height(14, 20);
          letter-spacing: 0.014rem;
          .MuiDayCalendar-root {
            .MuiPickersSlideTransition-root {
              // min-height: 26rem;
              @include custom-scroll;
            }
            .MuiDayCalendar-header {
              > span {
                width: 4rem;
                color: $grey4;
              }
            }
            .MuiButtonBase-root {
              color: $black1;
              border-radius: 0.8rem;
              &.Mui-disabled:not(.Mui-selected) {
                color: #83889e;
              }
              &.MuiPickersDay-root:not(.Mui-selected) {
                border-color: #6200ea;
              }
              &.MuiPickersDay-root.Mui-selected {
                color: $white !important;
                border-color: #7c4dff;
                background-color: #7c4dff;
              }
            }
            .MuiDayCalendar-weekContainer {
              .MuiPickersDay-root {
                width: 4rem;
                height: 4rem;
              }
            }
          }
        }
        .MuiPickersFadeTransitionGroup-root {
          .MuiYearCalendar-root {
            @include custom-scroll;
            .MuiPickersYear-yearButton {
              border-radius: 0.8rem;
              &.Mui-selected {
                background-color: #7c4dff;
              }
            }
          }
        }
      }
    }
  }
}
